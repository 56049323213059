import { Flex, FlexProps } from "@chakra-ui/react";
import { Dashboard } from "../Components/main-page/dashboard/index";

export function Dashboards() {
  const handleUpload = (files: File[]) => {};

  return (
    <Flex {...styles.container()}>
      <Flex justifyContent="center">
        <Dashboard onUpload={handleUpload} />
      </Flex>
    </Flex>
  );
}

const styles = {
  container: (): FlexProps => ({
    h: "100vh",
    flexDir: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    bg: "#181818",
  }),
};
