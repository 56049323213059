import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";

import AssistenceImage from "../../assets/images/assistence.png";

export const ComunicationCard = () => {
  const [lessThan524] = useMediaQuery(["(max-width: 1000px)"]);

  return (
    <Flex
      px={lessThan524 ? "2rem" : "4rem"}
      direction={"column"}
      py={lessThan524 ? "2rem" : "4rem"}
      bg={"#000"}
      id="assistencia"
      align={lessThan524 ? "center" : "end"}
    >
      <Box w={lessThan524 ? "100%" : "50%"}>
        <Text
          color="#DCBC2E"
          fontSize={lessThan524 ? "1.6rem" : "2.5rem"}
          textAlign={"center"}
          mb={"1.5rem"}
          display={"inline-block"}
        >
          ASSISTÊNCIA E SUPORTE
        </Text>

        <Text
          mb={"1.5rem"}
          color={"#fff"}
          fontSize={lessThan524 ? "1.2rem" : "1.4rem"}
          textAlign={lessThan524 ? "center" : "start"}
        >
          Elevando a experiência de viagem, o aplicativo oferece um escudo de suporte contínuo que 
          envolve motoristas e passageiros, garantindo serenidade a cada trajeto.
        </Text>
      </Box>

      <Box w={"100%"} mt={"2rem"}>
        <Image src={AssistenceImage} />
      </Box>
    </Flex>
  );
};
