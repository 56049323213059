import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";

import SupportIcon from "../../assets/images/support.png";
import SecurityIcon from "../../assets/images/security.png";
import LikeIcon from "../../assets/images/like.png";
import ChatIcon from "../../assets/images/chat.png";

export const PaymentCard = () => {
  const [lessthan524] = useMediaQuery(["(max-width: 1000px)"]);

  return (
    <Flex
      px={lessthan524 ? "2rem" : "4rem"}
      py={lessthan524 ? "1rem" : "8rem"}
      bg={"#000"}
      id="compromisso"
      alignItems={"center"}
      direction={"column"}
    >
      <Text
        color="#DCBC2E"
        textAlign={"center"}
        display={"inline-block"}
        fontSize={lessthan524 ? "1.4rem" : "2.5rem"}
        mb={lessthan524 ? "3rem" : "10rem"}
      >
        COMPROMISSO COM {!lessthan524 ?? <br />} O PROFISSIONALISMO
      </Text>

      <Flex
        alignItems={"center"}
        justifyContent={"space-around"}
        wrap={"wrap"}
        w={"100%"}
      >
        <Box
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
          maxWidth="300px"
          mb={"3rem"}
        >
          <Image
            src={SupportIcon}
            objectFit={"contain"}
            w={lessthan524 ? "50%" : "80%"}
          />

          <Text
            color={"#fff"}
            fontWeight={"bold"}
            fontSize={lessthan524 ? "1rem" : "1.6rem"}
            textAlign={"center"}
          >
            SUPORTE
          </Text>
        </Box>

        <Box
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
          maxWidth="300px"
          mb={"3rem"}
        >
          <Text
            color={"#fff"}
            fontWeight={"bold"}
            fontSize={lessthan524 ? "1rem" : "1.6rem"}
            textAlign={"center"}
          >
            SEGURANÇA
          </Text>

          <Image
            src={SecurityIcon}
            objectFit={"contain"}
            w={lessthan524 ? "50%" : "80%"}
          />
        </Box>

        <Box
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
          maxWidth="300px"
          mb={"3rem"}
        >
          <Image
            src={LikeIcon}
            objectFit={"contain"}
            w={lessthan524 ? "50%" : "80%"}
          />

          <Text
            color={"#fff"}
            fontWeight={"bold"}
            fontSize={lessthan524 ? "1rem" : "1.6rem"}
            textAlign={"center"}
          >
            PET FRIENDLY E <br />
            BIKE FRIENDLY
          </Text>
        </Box>

        <Box
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
          maxWidth="300px"
          mb={"3rem"}
        >
          <Text
            color={"#fff"}
            fontWeight={"bold"}
            fontSize={lessthan524 ? "1rem" : "1.6rem"}
            textAlign={"center"}
          >
            COMUNICAÇÃO
          </Text>
          <Image
            src={ChatIcon}
            objectFit={"contain"}
            w={lessthan524 ? "50%" : "80%"}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
