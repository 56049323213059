export const cpfFormatter = {
  format: (document:string) => {
    document = document.replace(/\D/g, '');
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
   },
  unformat: (document:string) => {
    
    return document.replace(/\D/g, '');
  }
}
