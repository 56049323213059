import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import z from "zod";

const FormData = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string({ required_error: "'email' ou 'telefone' deve ser preenchido"}),
    phoneNumber: z.string({ required_error: "'email' ou 'telefone' deve ser preenchido"}),
    operatorCode: z.string({ required_error: "Selecione o Recrutador" }),
  })
  .refine((data) => data.email || data.phoneNumber, {
    message:
      "Pelo menos um dos campos 'email' ou 'telefone' deve ser preenchido.",
  });

type TCreateDriverForm = z.infer<typeof FormData>;

export const OpitionalSignUp = () => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [operators, setOperators] = useState<{ _id: string; name: string }[]>(
    []
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TCreateDriverForm>({
    resolver: zodResolver(FormData),
  });

  const onSubmit: SubmitHandler<TCreateDriverForm> = async (data) => {
    setIsloading(true);
    console.log("form data", data);
    setIsloading(false);

    // axios
    //   .post(
    //     // "https://api.n1car.com.br/api/v1/auth/operator/pre-registration",
    //     "",
    //     {
    //       driver: {
    //         personal: {
    //           firstName: data.firstName,
    //           lastName: data.lastName,
    //         },
    //       },
    //       user: {
    //         contact: {
    //           email: data.email,
    //           phoneNumber: data.phoneNumber,
    //         },
    //       },
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     if (response.status === 201) {
    //       const { token, user, parent } = response.data;
    //       signIn(token, user, parent);
    //       setTimeout(() => {
    //         navigate(`/dashboard`);
    //       }, 1000);
    //       setIsloading(false);
    //       return;
    //     }
    //   })
    //   .catch(function (error) {
    //     if (error.response) {
    //       if (error.response.status === 201) {
    //         alert("Formulário enviado com sucesso!");
    //         return;
    //       }

    //       if (error.response.status === 409) {
    //         switch (error.response.data.internalError) {
    //           case "AUTH002":
    //             alert("Email ja está em uso.");
    //             break;
    //           case "AUTH003":
    //             alert("Telefone ja está em uso.");
    //             break;
    //           case "AUTH004":
    //             alert("RG ja está em uso.");
    //             break;
    //           case "AUTH005":
    //             alert("CPF ja está em uso.");
    //             break;
    //           default:
    //             alert("Erro ao cadastrar usuário");
    //         }
    //       }

    //       if (error.response.status !== 201 && error.response.status !== 409) {
    //         alert("Erro ao enviar formulário, tente novamente mais tarde.");
    //         return;
    //       }
    //     } else if (error.request) {
    //       console.log(error.request);
    //     } else {
    //       console.log("Error", error.message);
    //     }
    //     setIsloading(false);
    //   });
  };

  const handleGetOperatorId = async () => {
    const url = "http://api.n1car.com.br/api/v1/operators";

    const response = await axios.get(url);
    setOperators(response.data as { _id: string; name: string }[]);

    return response;
  };

  useEffect(() => {
    handleGetOperatorId();
  }, []);

  return (
    <Flex
      p={{ sm: 6, md: 10 }}
      flexDirection="column"
      maxW={1024}
      mx="auto"
      justify="center"
      minH="100vh"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          gap={6}
          p={10}
          color="#fff"
          borderWidth={1}
          borderColor="#ffcc00"
        >
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Dados Pessoais
            </Text>
            <SimpleGrid
              borderWidth={1}
              p={4}
              borderColor="#ffcc00"
              columns={{ sm: 1, md: 2 }}
              gap={6}
            >
              <Controller
                defaultValue=""
                control={control}
                name="firstName"
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Nome</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Nome"
                    />
                  </FormControl>
                )}
              />

              <Controller
                defaultValue=""
                control={control}
                name="lastName"
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Sobrenome</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Sobrenome"
                    />
                  </FormControl>
                )}
              />
            </SimpleGrid>
          </Box>

          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Contatos
            </Text>
            <SimpleGrid
              borderWidth={1}
              p={4}
              borderColor="#ffcc00"
              columns={{ sm: 1, md: 2 }}
              gap={6}
            >
              <Controller
                defaultValue=""
                control={control}
                name="email"
                render={({ field }) => (
                  <FormControl isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Email"
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />
              <FormControl isInvalid={!!errors.phoneNumber}>
                <FormLabel htmlFor="phoneNumber">Telefone</FormLabel>
                <Controller
                  defaultValue=""
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Telefone"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.phoneNumber?.message}
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Código do Operador
            </Text>
            <SimpleGrid
              borderWidth={1}
              p={4}
              borderColor="#ffcc00"
              columns={{ sm: 1, md: 2 }}
              gap={6}
            >
              <FormControl>
                <Controller
                  control={control}
                  name="operatorCode"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.operatorCode != null}>
                      <FormLabel>Operador(a)</FormLabel>
                      <Select {...field}>
                        {operators &&
                          operators.map((item, idx) => (
                            <option
                              key={idx}
                              style={{ color: "black" }}
                              value={item._id}
                            >
                              {item.name}
                            </option>
                          ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.operatorCode?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <FormErrorMessage>
                  {errors.operatorCode?.message}
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Box>
          {!isLoading ? (
            <Button
              _hover={{ bgColor: "#ffcc80" }}
              bgColor="#ffcc00"
              p={4}
              py={6}
              alignItems="center"
              justifyContent="center"
              type="submit"
              title="Continuar"
            >
              Continuar
            </Button>
          ) : (
            <Button
              bgColor="#ffcc00"
              p={4}
              py={6}
              alignItems="center"
              justifyContent="center"
            >
              <HStack spacing={5}>
                <Text>Enviando...</Text>
                <CircularProgress isIndeterminate size="30px" color="black" />
              </HStack>
            </Button>
          )}
        </Box>
      </form>
    </Flex>
  );
};
