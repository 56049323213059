import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

interface IUser {
  id: string;
  avatarUrl: string;
  condition: "pending" | "approved" | "reproved" | "under-review";
  username: string;
}

interface IParent {
  id: string;
  firstName: string;
  lastName: string;
}

export interface AuthContextValues {
  user: IUser | null;
  token: string | null;
  parent: IParent | null;
  signOut: () => void;
  signIn: (token: string, user: IUser, parent: IParent) => void;
  updateUserCondition: (
    newCondition: "pending" | "approved" | "reproved" | "under-review"
  ) => void;
}

const AuthContext = createContext<AuthContextValues>({} as AuthContextValues);

export const AuthContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [token, setToken] = useState("");
  const [parent, setParent] = useState<IParent | null>(null);

  useEffect(() => {
    const userSession = sessionStorage.getItem("@auth:user");
    const tokenSession = sessionStorage.getItem("@auth:token");
    const parentSession = sessionStorage.getItem("@auth:parent");

    if (userSession && tokenSession && parentSession) {
      setUser(JSON.parse(userSession));
      setToken(tokenSession);
      setParent(JSON.parse(parentSession));
    }
  }, []);

  const signOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    setParent(null);
    setUser(null);
    setToken("");
  };

  const signIn = (token: string, user: IUser, parent: IParent) => {
    sessionStorage.setItem("@auth:token", token);
    sessionStorage.setItem("@auth:user", JSON.stringify(user));
    sessionStorage.setItem("@auth:parent", JSON.stringify(parent));
    sessionStorage.removeItem("driverId");

    setUser(user);
    setParent(parent);
    setToken(token);
  };

  const updateUserCondition = (
    newCondition: "pending" | "approved" | "reproved" | "under-review"
  ) => {
    setUser((prev) => {
      return { ...prev, condition: newCondition } as IUser;
    });
  };

  return (
    <AuthContext.Provider
      value={{ user, token, parent, signIn, signOut, updateUserCondition }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
