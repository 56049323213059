import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { UsedTechnologies } from "./modals/used-technologies";
import { HowUsedTechnologies } from "./modals/how-to-used";

import { COOKIES_PAGE_TEXTS } from "../../mocks/cookies";

export const Cookies = () => {
  const [lessThan1048] = useMediaQuery(["(max-width: 1048px)"]);

  return (
    <Flex
      w={lessThan1048 ? "90%" : "60%"}
      marginY="2rem"
      flexDir="column"
      color="white"
      fontFamily="Poppins"
    >
      <Text fontSize="3xl" fontWeight="bold" marginBottom="1rem" textColor="#FDD835">
        POLÍTICA DE COOKIES
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginBottom="0.5rem" textColor="#FDD835">
        AD TECNOLOGIA E SOLUCOES LTDA
      </Text>
      <Text fontSize="0.9rem">{COOKIES_PAGE_TEXTS.texts.first}</Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {COOKIES_PAGE_TEXTS.texts.second}
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        Tecnologias Utilizadas
      </Text>
      <Box>
        <UsedTechnologies />
      </Box>
      <Text marginY="1rem" fontSize="0.9rem">
        {COOKIES_PAGE_TEXTS.texts.third}
      </Text>
      <Text fontSize="xl" fontWeight="bold" textColor="#FDD835">
        Como essas tecnologias são utilizadas
      </Text>
      <Text marginY="1rem" fontSize="0.9rem">
        Abaixo, descrevemos as maneiras como podemos usar cookies:
      </Text>
      <Box>
        <HowUsedTechnologies />
      </Box>
      <Text marginTop="1rem" fontSize="0.9rem">
        {COOKIES_PAGE_TEXTS.texts.fourth}
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {COOKIES_PAGE_TEXTS.texts.fifth}
      </Text>
    </Flex>
  );
};
