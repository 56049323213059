import React from "react";
import {
  Text,
  Image,
  Flex,
  Box,
  useMediaQuery,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import Welcome from "../../assets/images/Welcome.png";
import Logo from "../../assets/logo.png";

export const DetailCard = () => {
  const [lessThan524] = useMediaQuery(["(max-width: 1000px)"]);

  return (
    <Flex
      h={lessThan524 ? "20rem" : "89vh"}
      justifyContent="center"
      alignItems="center"
      flex={1}
      position={"relative"}
    >
      <Image h={"100%"} w={"100%"} objectFit={"cover"} src={Welcome} />

      <Box
        alignItems={"center"}
        justifyContent={"center"}
        position="absolute"
        h={"100%"}
        display={"flex"}
        flexDirection={"column"}
        w={"100%"}
        bg={"#000000ca"}
      >
        <Image src={Logo} w={lessThan524 ? "25%" : "10%"} marginX={"auto"} />

        <Text
          padding={"1rem"}
          fontWeight={"thin"}
          fontSize={["sm", "md", "lg", "xl", "2xl", "3xl"]}
          color="#FFF"
          textAlign={"center"}
        >
          Revolucionando a Mobilidade Urbana no Brasil
        </Text>

        <Link to="/sign-up">
          <Button
            alignContent={"flex-end"}
            borderColor="#dddfe2"
            textColor="#DCBC2E"
            _hover={{ bg: "black" }}
            _active={{
              bg: "#dddfe2",
              transform: "scale(0.98)",
              borderColor: "#bec3c9",
            }}
            variant="outline"
            size="lg"
            marginTop="1rem"
          >
            Seja nosso N1 DRIVER
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};
