import { Flex, FlexProps } from "@chakra-ui/react";
import { Header } from "../Components/header";
import { Support } from "../Components/support/index";
import { Footer } from "../Components/footer";

export default function SupportPage() {
  return (
    <Flex {...styles.container()}>
      <Header />
      <Flex justifyContent="center">
        <Support />
      </Flex>
      <Footer />
    </Flex>
  );
}

const styles = {
  container: (): FlexProps => ({
    h: "100vh",
    flexDir: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    bg: "#181818",
  }),
};
