import axios from "axios";
import { http } from "../index";
import {
  CreateDriverDto,
  SignUpDto,
  SignInDto,
  TUploadImageResponse,
  TUdateUserDocumentDto,
  TUploadImageDto,
} from "./dtos";

export const AuthRepository = {
  createDriver: async (body: CreateDriverDto) => {
    try {
      const response = await http.post(`/drivers`, body);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      } else {
      }
      throw error;
    }
  },

  signUp: async (body: SignUpDto) => {
    const response = await http.post(`/auth/sign-up`, body);
    return response.data;
  },

  signIn: async (body: SignInDto) => {
    const response = await http.post(`/auth/sign-in`, body);
    return response.data;
  },

  uploadImage: async (body: FormData) => {
    const response = await http.post<TUploadImageResponse>("/uploads", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  updateUserDocuments: async (body: TUdateUserDocumentDto, id: string) => {
    const response = await http.patch(`/drivers/documents/${id}`, body);
    return response.data;
  },
};
