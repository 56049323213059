import {
  Box,
  Button,
  Heading,
  Text,
  Img,
  Input,
  Textarea,
  Select,
  useMediaQuery,
  Grid,
} from "@chakra-ui/react";
import support from "../../assets/images/support.png";
import { useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";

export function Support() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const [mensagem, setMensagem] = useState("");
  const [assunto, setAssunto] = useState("");
  const [nome, setNome] = useState("");
  // const [recaptchaValue, setRecaptchaValue] = useState(null);

  function sendEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (nome === "" || mensagem === "" || assunto === "") {
      alert("Preencha todos os campos");
      return;
    }
    const emailSubject = encodeURIComponent(`Topico escolhido: ${assunto}`);
    const emailBody = encodeURIComponent(
      `Nome: ${nome}\nMensagem: ${mensagem}`
    );
    const mailtoLink = `mailto:suporte@n1car.com.br?subject=${emailSubject}&body=${emailBody}`;

    const anchor = document.createElement("a");
    anchor.href = mailtoLink;
    anchor.click();

    setMensagem("");
    setAssunto("");
    setNome("");
    // setRecaptchaValue(null);
  }
  const onChange = () => {};
  const topics = [
    "Conta",
    "Problemas com uma viagem",
    "Ganhos e repasse",
    "Problemas com a conta e o app",
    "Segurança",
    "Acessibilidade",
    "Dados e privacidade",
    "Assinatura e fidelidade",
  ];
  const [screen1000] = useMediaQuery("(max-width: 1000px)");
  const [screen700] = useMediaQuery("(max-width: 700px)");
  const [screen400] = useMediaQuery("(max-width: 400px)");
  return (
    <Grid backgroundColor={"black"} paddingBottom={2}>
      <Heading
        backgroundColor={"black"}
        color="#DCBC2E"
        fontWeight={"300"}
        fontFamily={"Montserrat"}
        paddingTop={"8"}
        textAlign={"center"}
        fontSize={"40"}
      >
        SUPORTE
      </Heading>
      <div>
        <Box
          w={screen700 ? "90%" : "100vw"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={screen1000 ? "column" : "row"}
          height={screen1000 ? "auto" : "510px"}
          justifyContent={screen1000 ? "center" : "center"}
        >
          <Text
            color={"white"}
            justifyItems={"center"}
            fontSize={24}
            textAlign={"center"}
          >
            <Box
              paddingLeft={screen1000 ? 20 : 20}
              marginLeft={screen400 ? 0 : 6}
              paddingEnd={screen1000 ? 10 : 0}
            >
              <Img src={support} />
            </Box>
            Bem-vindo, <b style={{ color: "#DCBC2E" }}>{name} !</b> <br />A{" "}
            <b>N1 Car</b> tem um suporte <br /> para melhor atender nossos
            parceiros!
            <br />
            <br />
          </Text>
          <Box>
            <Text
              backgroundColor={"black"}
              color={"#DCBC2E"}
              fontSize={"26"}
              marginLeft={screen1000 ? 20 : 400}
            >
              Fale com nosso suporte!
            </Text>
            <Box
              marginLeft={screen1000 ? 20 : 400}
              marginEnd={screen1000 ? 10 : 50}
            >
              <form onSubmit={sendEmail} noValidate>
                <Text
                  fontSize={"22"}
                  borderRadius={4}
                  height={10}
                  color={"white"}
                >
                  Selecione um tópico:
                </Text>
                <Select
                  w={screen1000 ? "100%" : "auto"}
                  color={"black"}
                  bg={"white"}
                  value={assunto}
                  onChange={(e) => setAssunto(e.target.value)}
                  style={{ padding: "0.3rem" }}
                >
                  <option value="">Selecionar</option>
                  {topics.map((topic, index) => (
                    <option key={index} value={topic}>
                      {topic}
                    </option>
                  ))}
                </Select>
                <Textarea
                  w={screen1000 ? 350 : 360}
                  h={screen1000 ? 40 : 150}
                  color={"black"}
                  marginTop={2}
                  padding={1}
                  background={"white"}
                  placeholder="Mensagem:"
                  required
                  onChange={(e) => setMensagem(e.target.value)}
                  value={mensagem}
                ></Textarea>
                <br />
                {/* <Box
                  paddingTop={screen1000 ? 2 : 2}
                  paddingLeft={screen1000 ? 6 : 8}
                // >
                //   <ReCAPTCHA
                //     sitekey="6Ld8WqwnAAAAAEZh-rjb7WUSVhaqlSB-utJAbdYU"
                //     onChange={onChange}
                //   />
                </Box> */}
                <Button
                  textAlign={"center"}
                  w={screen1000 ? 200 : 200}
                  marginLeft={screen1000 ? 20 : 20}
                  marginTop={"1"}
                  alignItems={"center"}
                  background={"#DCBC2E"}
                  type="submit"
                >
                  Enviar
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
      </div>
    </Grid>
  );
}
export default Support;
