import { Box, Flex, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useMediaQuery,} from "@chakra-ui/react";

import { COOKIES_POLICIES_HOW_TO_USE } from "../../../mocks/cookies";

export function HowUsedTechnologies() {
  const [lessThan756] = useMediaQuery(["(max-width: 756px)"]);
  return (
    <Flex flexDir="column" alignItems="flex-start">
      <Accordion allowToggle w="100%">
        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES_HOW_TO_USE.auth.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <Text>{COOKIES_POLICIES_HOW_TO_USE.auth.description}</Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES_HOW_TO_USE.security.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES_HOW_TO_USE.security.description}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES_HOW_TO_USE.preferences.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES_HOW_TO_USE.preferences.description}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES_HOW_TO_USE.functional.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES_HOW_TO_USE.functional.description}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES_HOW_TO_USE.plugins.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES_HOW_TO_USE.plugins.description}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES_HOW_TO_USE.personalizedAdvertising.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES_HOW_TO_USE.personalizedAdvertising.description}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES_HOW_TO_USE.analysisSearch.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES_HOW_TO_USE.analysisSearch.description}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}
