import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import Taxi from "../../assets/images/taxi-driver.png";
import Location from "../../assets/images/location.png";

export const WomanCard = () => {
  const [lessThan524] = useMediaQuery(["(max-width: 1000px)"]);

  return (
    <Flex
      direction={"column"}
      align={"center"}
      p={lessThan524 ? "2rem" : "4rem"}
      bg={"#000"}
    >
      <Heading
        color="#DCBC2E"
        fontWeight={"300"}
        fontFamily={"Montserrat"}
        mb={"4rem"}
      >
        SEGURANÇA
      </Heading>

      <Flex align={"top"} direction={lessThan524 ? "column" : "row"}>
        <Box flex={1}>
          <Flex align="center" direction={lessThan524 ? "column" : "row"}>
            <Image src={Taxi} h={"6rem"} />

            <Heading
              fontFamily={"Montserrat"}
              color="#fff"
              flex={1}
              textAlign={"center"}
              fontSize={"1.5rem"}
            >
              CORRIDAS DA FEMININAS
            </Heading>
          </Flex>

          <Text
            color="#fff"
            fontSize={"1.3rem"}
            p={"2rem"}
            px={lessThan524 ? "0rem" : "2rem"}
            textAlign={lessThan524 ? "center" : "justify"}
          >
            As corridas realizadas por mulheres são uma iniciativa que vem
            ganhando destaque e importância na atualidade. Com o objetivo de
            proporcionar um ambiente seguro e confortável para as passageiras,
            essas corridas são realizadas exclusivamente por motoristas mulheres
          </Text>
        </Box>

        <Box flex={1}>
          <Flex
            align="center"
            direction={lessThan524 ? "column" : "row"}
            justify="end"
          >
            <Heading
              fontFamily={"Montserrat"}
              color="#fff"
              fontSize={"1.5rem"}
              flex={1}
              textAlign={"center"}
              order={lessThan524 ? 1 : 0}
            >
              ENVIO DE IMAGEM <br /> E LOCALIZAÇÃO
            </Heading>

            <Image src={Location} h={"6rem"} />
          </Flex>

          <Text
            color="#fff"
            fontSize={"1.3rem"}
            p={"2rem"}
            px={lessThan524 ? "0rem" : "2rem"}
            textAlign={lessThan524 ? "center" : "justify"}
          >
            A segurança no envio de imagens e localização em aplicativos de
            transporte, é uma preocupação essencial para proteger a privacidade
            e garantir a tranquilidade dos usuários, com um serviço ágil e
            eficaz.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
