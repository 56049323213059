import {
  Heading,
  Text,
  Image,
  Flex,
  Box,
  Grid,
  useMediaQuery,
} from "@chakra-ui/react";

import Payment from "../../assets/images/payment.png";

export const WelcomeCard = () => {
  const [lessthan524] = useMediaQuery("(max-width: 1000px)");

  return (
    <Grid gridTemplateColumns={lessthan524 ? "1fr" : "3fr 2fr"}>
      <Flex
        bg="#000"
        // px="2rem"
        p={lessthan524 ? "2rem" : "4rem"}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Heading
          textAlign="center"
          color="white"
          marginBottom="2rem"
          fontFamily={"Montserrat"}
          fontWeight="light"
          fontSize={lessthan524 ? "1.5rem" : "2rem"}
        >
          SEJA BEM-VINDO
          <br /> AO{" "}
          <Text
            color="#DCBC2E"
            display="inline-block"
            fontSize={lessthan524 ? "1.5rem" : "2rem"}
            fontWeight="extrabold"
          >
            N1 CAR
          </Text>{" "}
          APP
        </Heading>

        <Text
          textAlign="center"
          fontSize={"1.4rem"}
          fontFamily={"Montserrat"}
          fontWeight="light"
          color="#DCBC2E"
        >
          Popular, Executivo e Taxi.
        </Text>

        <Text
          color="white"
          fontSize={lessthan524 ? "1.2rem" : "1.6rem"}
          textAlign="center"
          fontWeight="light"
          mt={"1rem"}
          display={"block"}
        >
          A N1 CAR busca transformar o cenário de mobilidade urbana no Brasil,
          por meio de seu inovador serviço de <b>assinatura mensal</b>. Abordamos o
          foco na rentabilidade dos motoristas, segurança dos passageiros e
          conveniência, bem como o compromisso da empresa com profissionalismo,
          suporte e inclusão.
        </Text>
      </Flex>

      <Box h={"100%"} w={"100%"} display={lessthan524 ? "none" : "block"}>
        <Image h={"100%"} w={"100%"} src={Payment} objectFit={"cover"} />
      </Box>
    </Grid>
  );
};
