import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
} from "@chakra-ui/react";
import { useState } from "react";

import { PatternFormat } from "react-number-format";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthRepository } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { cpfFormatter } from "../../utils/formatters/cpf";
import { cpfValidator } from "../../utils/validators/cpf";
import { rgValidator } from "../../utils/validators/rg";

const FormData = z.object({
  firstName: z.string({ required_error: "Campo obrigatório" }),
  lastName: z.string({ required_error: "Campo obrigatório" }),
  gender: z.string({ required_error: "Campo obrigatório" }),
  // driver: z.object({
  //   driverType: z.string({ required_error: "Campo obrigatório" }),
  //   taxiDriverLicense: z.string({ required_error: "Campo obrigatório" }),
  // }),
  // driver: z
  //   .object({
  //     driverType: z.string(),
  //     taxiDriverLicense: z.string(),
  //   })
  //   .refine((data) => data.driverType === "taxi", {
  //     message: "Campo obrigatório",
  //   }),
  birthDate: z.string({ required_error: "Campo obrigatório" }),
  nationality: z.string({ required_error: "Campo obrigatório" }),
  address: z.object({
    postcode: z.string({ required_error: "Campo obrigatório" }),
    city: z.string({ required_error: "Campo obrigatório" }),
    uf: z
      .string({ required_error: "Campo obrigatório" })
      .length(2, "2 Caractéres"),
    neighborhood: z.string({ required_error: "Campo obrigatório" }),
    street: z.string({ required_error: "Campo obrigatório" }),
    houseNumber: z.string({ required_error: "Campo obrigatório" }),
  }),
  naturalness: z.object({
    state: z.string({ required_error: "Campo obrigatório" }),
    city: z.string({ required_error: "Campo obrigatório" }),
    uf: z
      .string({ required_error: "Campo obrigatório" })
      .length(2, "2 Caractéres"),
  }),
  documents: z.object({
    rg: z.string({ required_error: "Campo obrigatório" }),
    cpf: z.string({ required_error: "Campo obrigatório" }),
  }),
});

type TCreateDriverForm = z.infer<typeof FormData>;

export const SignUp = () => {
  const [nationality, setNationality] = useState("");
  const [isTaxy, setIsTaxi] = useState(false);

  const handleChange = (selectedValue: string) => {
    if (selectedValue === "taxi") {
      setIsTaxi(true);
    } else {
      setIsTaxi(false);
    }
    return;
  };

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<TCreateDriverForm>({
    resolver: zodResolver(FormData),
    defaultValues: {
      gender: "male",
    },
  });

  const { documents, firstName, lastName, birthDate } = watch();

  const onSubmit: SubmitHandler<TCreateDriverForm> = async ({
    address,
    birthDate,
    firstName,
    gender,
    lastName,
    nationality,
    naturalness,
    documents,
    // driver,
  }) => {

    if (!accepted) return onOpen();
    try {
      const date = new Date(birthDate).toISOString();

      // if (!rgValidator(documents.rg)) return alert("Digite um RG Válido");

      if (!cpfValidator(documents.cpf)) return alert("Digite um CPF Válido");

      const response = await AuthRepository.createDriver({
        address,
        personal: {
          birthDate: date,
          firstName,
          lastName,
          gender,
          nationality,
          naturalness,
        },
        documents: {
          ...documents,
          rg: documents.rg,
          cpf: cpfFormatter.unformat(documents.cpf),
        },
      });

      // if (response) {
      //   sessionStorage.setItem("driverId", response.id);
      //   navigate("/user/sign-up");
      //   console.log("driver id setado no local storage", response.id);
      // }

      navigate(`/user/sign-up/${response.id}`);
    } catch (error: any) {
      alert(
        "Ocorreu um erro ao avançar o cadastro, por favor tente novamente mais tarde!"
      );
      console.log(error);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [accepted, setAccepted] = useState(false);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Políticas de Privacidade</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Eu,{firstName} {lastName}, portador(a) do RG nº{documents?.rg}, CPF{" "}
            {documents?.cpf}, nascido(a) na data,
            {birthDate}, autorizo expressamente que a empresa N1CAR TECNOLOGIA E
            AGENCIAMENTO DE SERVICOS LTDA, doravante denominada N1Car, em razão
            do processo de pré-cadastro de motorista, disponha dos meus dados
            pessoais e dados pessoais sensíveis, a fim de possibilitar o efetivo
            cadastro. CLÁUSULA PRIMEIRA - Dados: 1) Nome completo; 2) Data de
            nascimento; 3) Número e Imagem da Carteira Nacional de Habilitação
            (CNH); 4) Número e Imagem do Cadastro de Pessoas Físicas – CPF; 5)
            Endereço completo e Imagem do Comprovante de residência; 6) Números
            de telefone, WhatsApp, e endereços eletrônicos; CLÁUSULA SEGUNDA -
            Finalidade do tratamento de dados: O(A) MOTORISTA autoriza,
            expressamente, que a N1Car utilize os dados pessoais e dados
            pessoais sensíveis listados neste termo para as seguintes
            finalidades: a) Permitir que a EMPRESA identifique e entre em
            contato com o(a) MOTORISTA, em razão do Processo de Cadastramento
            para que possa ser um Motorista apto a figurar na Plataforma da
            N1Car. b) Para aplicação dos critérios de avaliação, seleção e
            pré-cadastro na Plataforma N1Car. c) Para procedimentos de
            efetivação do cadastro e, se atendidas todas as condições, para
            posterior efetivação na plataforma; d) Para cumprimento, pela N1Car,
            de obrigações impostas por órgãos de fiscalização; e) Quando
            necessário, para atender aos interesses legítimos da Empresa ou de
            terceiros, exceto no caso de prevalecerem direitos e liberdades
            fundamentais do titular que exijam a proteção dos dados pessoais;
            CLÁUSULA TERCEIRA - Responsabilidade pela Segurança dos Dados: A
            N1Car se responsabiliza por manter medidas de segurança técnicas e
            administrativas suficientes a proteger os dados pessoais do(a)
            MOTORISTA, comunicando ao (a) MOTORISTA, caso aconteça qualquer
            incidente de segurança que possa acarretar risco ou dano relevante,
            conforme o artigo 48 da Lei 13.709/2018. CLÁUSULA QUARTA - Término
            do Tratamento dos Dados: Fica permitido à N1Car manter e utilizar os
            dados pessoais do(a) MOTORISTA durante todo o período em que esse
            figurar na Plataforma N1Car, para as finalidades relacionadas neste
            termo e, ainda, após o término da permanência para cumprimento da
            obrigação legal ou impostas por órgãos de fiscalização, nos termos
            do artigo 16 da Lei 13.709/2018. CLÁUSULA QUINTA - Direito de
            Revogação do Consentimento: O(A) MOTORISTA poderá revogar seu
            consentimento, a qualquer tempo, por carta eletrônica ou escrita,
            conforme o parágrafo 5º do artigo 8º combinado com o inciso VI do
            caput do artigo 18 e com o artigo 16 da Lei 13.709/2018. CLÁUSULA
            SEXTA - Tempo de Permanência dos Dados Recolhidos: O(A) MOTORISTA
            fica ciente de que a N1Car deverá permanecer com os seus dados pelo
            período de duração de todo o processo de cadastramento, o período de
            efetivação do cadastro e pelo prazo prescricional.
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Discordo
            </Button>
            <Button
              colorScheme="yellow"
              onClick={() => {
                setAccepted(true);
                onClose();
              }}
            >
              Concordo
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        p={{ sm: 6, md: 10 }}
        flexDirection="column"
        maxW={1024}
        mx="auto"
        justify="center"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            onSubmit={() => {}}
            display="flex"
            flexDirection="column"
            gap={6}
            p={12}
            color="#fff"
            borderWidth={1}
            borderColor="#ffcc00"
          >
            <Box>
              <Text fontWeight="bold" fontSize="xl">
                Dados Pessoais
              </Text>
              <SimpleGrid
                borderWidth={1}
                p={4}
                borderColor="#ffcc00"
                columns={{ sm: 1, md: 2 }}
                gap={6}
              >
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.firstName != null}>
                      <FormLabel>Nome</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Nome"
                      />
                      <FormErrorMessage>
                        {errors.firstName?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.lastName != null}>
                      <FormLabel>Sobrenome</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Sobrenome"
                      />
                      <FormErrorMessage>
                        {errors.lastName?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.gender != null}>
                      <FormLabel>Gênero</FormLabel>
                      <Select {...field}>
                        <option style={{ color: "black" }} value="male">
                          Masculino
                        </option>
                        <option style={{ color: "black" }} value="female">
                          Feminino
                        </option>
                        <option style={{ color: "black" }} value="non-binary">
                          Não-binário
                        </option>
                      </Select>
                      <FormErrorMessage>
                        {errors.gender?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="birthDate"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.birthDate != null}>
                      <FormLabel>Data de nascimento</FormLabel>
                      <Input
                        {...field}
                        style={{ colorScheme: "dark" }}
                        type="date"
                        focusBorderColor="#fff"
                        placeholder="Data de Nascimento"
                      />
                      <FormErrorMessage>
                        {errors.birthDate?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="nationality"
                  render={({ field: { onChange, value } }) => (
                    <FormControl isInvalid={errors.nationality != null}>
                      <FormLabel>País</FormLabel>

                      <CountryDropdown
                        //@ts-expect-error
                        style={{
                          color: "#000",
                          fontSize: 20,
                          width: "100%",
                        }}
                        defaultOptionLabel="Selecione seu país"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setNationality(e);
                        }}
                      />
                      <FormErrorMessage>
                        {errors.nationality?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="naturalness.state"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.naturalness?.state != null}>
                      <FormLabel>Estado</FormLabel>

                      <RegionDropdown
                        //@ts-expect-error
                        style={{
                          color: "#000",
                          fontSize: 20,
                          width: "100%",
                        }}
                        country={nationality}
                        defaultOptionLabel="Selecione seu estado"
                        {...field}
                      />
                      <FormErrorMessage>
                        {errors.naturalness?.state?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="naturalness.city"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.naturalness?.city != null}>
                      <FormLabel>Cidade</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Cidade"
                      />

                      <FormErrorMessage>
                        {errors.naturalness?.city?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="naturalness.uf"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.naturalness?.uf != null}>
                      <FormLabel>UF</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="UF"
                      />
                      <FormErrorMessage>
                        {errors.naturalness?.uf?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                {/* <Controller
                  control={control}
                  name="driver.driverType"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.driver?.driverType != null}>
                      <FormLabel>Condução</FormLabel>
                      <Select
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          handleChange(e.target.value);
                        }}
                      >
                        <option style={{ color: "black" }} value="executive">
                          Executive
                        </option>
                        <option style={{ color: "black" }} value="popular">
                          Popular
                        </option>
                        <option style={{ color: "black" }} value="taxi">
                          Taxi
                        </option>
                      </Select>
                      <FormErrorMessage>
                        {errors.driver?.driverType?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                /> */}

                {/* {isTaxy ? (
                  <Controller
                    control={control}
                    name="driver.taxiDriverLicense"
                    render={({ field }) => (
                      <FormControl
                        isInvalid={errors.driver?.taxiDriverLicense != null}
                      >
                        <FormLabel>Documento do Taxi</FormLabel>
                        <Input
                          {...field}
                          focusBorderColor="#fff"
                          placeholder="Documento"
                        />
                        <FormErrorMessage>
                          {errors.driver?.taxiDriverLicense?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                ) : (
                  <Flex display="none">
                    <Controller
                      control={control}
                      name="driver.taxiDriverLicense"
                      defaultValue=" "
                      render={({ field }) => (
                        <FormControl
                          isInvalid={errors.driver?.taxiDriverLicense != null}
                        >
                          <FormLabel>Documento do Taxi</FormLabel>
                          <Input
                            {...field}
                            focusBorderColor="#fff"
                            placeholder="Documento"
                          />
                          <FormErrorMessage>
                            {errors.driver?.taxiDriverLicense?.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Flex>
                )} */}
              </SimpleGrid>
            </Box>

            <Box>
              <Text fontWeight="bold" fontSize="xl">
                Documentos
              </Text>
              <SimpleGrid
                borderWidth={1}
                p={4}
                borderColor="#ffcc00"
                columns={{ sm: 1, md: 2 }}
                gap={6}
              >
                <FormControl isInvalid={Boolean(errors.documents?.rg)}>
                  <FormLabel htmlFor="documents.rg">Número do RG</FormLabel>
                  <Controller
                    control={control}
                    name="documents.rg"
                    render={({ field: { onChange, name, value } }) => (
                      <Input
                        name={name}
                        value={value}
                        onChange={onChange}
                        focusBorderColor="#fff"
                        placeholder="Insira o número do RG"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.firstName?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={Boolean(errors.documents?.cpf)}>
                  <FormLabel htmlFor="documents.cpf">Número do CPF</FormLabel>
                  <Controller
                    control={control}
                    name="documents.cpf"
                    render={({ field: { onChange, name, value } }) => (
                      <PatternFormat
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeholder="Insira o número do CPF"
                        customInput={Input}
                        focusBorderColor="#fff"
                        format="###.###.###-##"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.documents?.cpf?.message}
                  </FormErrorMessage>
                </FormControl>
              </SimpleGrid>
            </Box>

            <Box>
              <Text fontWeight="bold" fontSize="xl">
                Endereço
              </Text>
              <SimpleGrid
                borderWidth={1}
                p={4}
                borderColor="#ffcc00"
                columns={{ sm: 1, md: 2 }}
                gap={6}
              >
                <Controller
                  control={control}
                  name="address.city"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.address?.city != null}>
                      <FormLabel>Cidade</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Cidade"
                      />
                      <FormErrorMessage>
                        {errors.address?.city?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="address.neighborhood"
                  render={({ field }) => (
                    <FormControl
                      isInvalid={errors.address?.neighborhood != null}
                    >
                      <FormLabel>Bairro</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Bairro"
                      />
                      <FormErrorMessage>
                        {errors.address?.neighborhood?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="address.street"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.address?.street != null}>
                      <FormLabel>Rua</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Rua"
                      />
                      <FormErrorMessage>
                        {errors.address?.street?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="address.houseNumber"
                  render={({ field }) => (
                    <FormControl
                      isInvalid={errors.address?.houseNumber != null}
                    >
                      <FormLabel>Número</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Número"
                      />

                      <FormErrorMessage>
                        {errors.address?.houseNumber?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <FormControl
                  isInvalid={Boolean(errors.address?.postcode != null)}
                >
                  <FormLabel>CEP</FormLabel>
                  <Controller
                    control={control}
                    name="address.postcode"
                    render={({ field: { onChange, name, value } }) => (
                      <PatternFormat
                        name={name}
                        value={value}
                        onChange={onChange}
                        customInput={Input}
                        focusBorderColor="#fff"
                        placeholder="CEP"
                        format="#####-###"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.address?.postcode?.message}
                  </FormErrorMessage>
                </FormControl>

                <Controller
                  control={control}
                  name="address.uf"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.address?.uf != null}>
                      <FormLabel>UF</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="UF"
                      />
                      <FormErrorMessage>
                        {errors.address?.uf?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </SimpleGrid>
            </Box>
            <Button
              _hover={{ bgColor: "#ffcc80" }}
              bgColor="#ffcc00"
              p={4}
              alignItems="center"
              justifyContent="center"
              type="submit"
              title="Continuar"
            >
              Continuar
            </Button>
          </Box>
        </form>
      </Flex>
    </>
  );
};

