import React, { useState } from "react";
import {
  Flex,
  Box,
  Button,
  Input,
  VStack,
  Image,
  Text,
  GridItem,
  Grid,
  useMediaQuery,
} from "@chakra-ui/react";
import SignIn from "../../assets/images/sign-in.png";
import Logo from "../../assets/logo.png";
import { AuthRepository } from "../../services/auth";
import { useAuthContext } from "../../context/auth";
import { Link, useNavigate } from "react-router-dom";

export const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const { signIn } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isMobile] = useMediaQuery("(max-width: 480px)");

  const handleLogin = async () => {
    
    if (!validateEmail(email)) {
      setErrorMessage("Email inválido");
      return;
    }

    if (password.length < 4) {
      setErrorMessage("A senha deve ter no mínimo 4 caracteres");
      return;
    }
    setLoading(true)
    try {

    const response = await AuthRepository.signIn({ email, password });

    if (response) {
      signIn(response.token, response.user, response.parent);
      navigate("/");
    }
  } catch (e) {
    alert("Erro ao logar! Por favor tente novamente!")
  } finally {
    setLoading(false)

  }


  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Box h="calc(100vh-104px)" w={"100vw"}>
      <Grid
        templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"}
        h={"full"}
        w={"full"}
      >
        <GridItem h={"full"}>
          <Flex justifyContent={"center"} flex={1} h={"full"}>
            <VStack
              spacing={4}
              maxW={"640px"}
              padding={5}
              h={"full"}
              align={"center"}
              justify={"center"}
            >
              <Image
                h={"75px"}
                src={Logo}
                alt="Login Image"
                paddingLeft={"10px"}
                marginBottom={"25px"}
              />
              <Text
                textColor={"white"}
                fontFamily={"Montserrat"}
                fontSize={"26px"}
                fontStyle={"bold"}
              >
                <b>Acesse a plataforma</b>
              </Text>
              <Text textColor={"white"}>
                Faça login ou registre-se para começar a faturar conosco.
              </Text>
              <Input
                height={"14"}
                placeholder="Email"
                borderColor={"yellow.500"}
                focusBorderColor={"yellow.300"}
                value={email}
                textColor={"white"}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                height={"14"}
                placeholder="Senha"
                borderColor={"yellow.500"}
                focusBorderColor={"yellow.300"}
                type="password"
                value={password}
                textColor={"white"}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <Text textColor={"yellow.500"} marginLeft={"400px"}>
                Esqueceu a senha?
              </Text> */}
              <Button
                isLoading={loading}
                loadingText="Entrando"
                height={"12"}
                width={"full"}
                colorScheme="yellow"
                onClick={handleLogin}
              >
                Entrar
              </Button>
              {errorMessage && <Text color="#ffcc00">{errorMessage}</Text>}
              <Text textAlign={"center"} textColor={"white"}>
                Ainda não tem uma conta?{" "}
                <b>
                  <Link to="/sign-up">Cadastre-se</Link>
                </b>
              </Text>
            </VStack>
          </Flex>
        </GridItem>
        <GridItem>
          <Image
            src={SignIn}
            alt="Login Image"
            width={"full"}
            height={"full"}
            objectFit={"cover"}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default SignInPage;
