import React from "react";
import {
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useMediaQuery,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";

import { HELP_MODALS } from "../../../mocks/help";

export function FrequentlyAskedQuestions() {
  const [lessThan756] = useMediaQuery(["(max-width: 756px)"]);

  return (
    <Flex flexDir="column" alignItems="flex-start">
      <Accordion allowMultiple w="100%">
        {Object.entries(HELP_MODALS).map(([key, value]) => (
          <AccordionItem key={key}>
            <h2>
              <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
                <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                  {value.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={lessThan756 ? "0.8rem" : "1rem"}>
              <OrderedList marginTop="0.5rem">
                {Object.entries(value)
                  .filter(([itemKey]) => itemKey !== "title")
                  .map(([itemKey, itemValue]) => (
                    <ListItem key={itemKey} paddingBottom="1rem">
                      {itemValue}
                    </ListItem>
                  ))}
              </OrderedList>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
}