import { Text } from "@chakra-ui/react";
import { useAuthContext } from "../../../context/auth";

export const Pannel = () => {
  const { user } = useAuthContext();

  switch (user?.condition) {
    case "approved":
      return (
        <>
          <Text fontSize="18px" textColor="white" textAlign="center">
            Parabéns! Você agora é um N1 driver
          </Text>

          <Text padding={20} color={"white"} textAlign="center">
            Todos seus documentos já foram validados e você já está pronto para
            realizar corridas.
          </Text>
        </>
      );
    case "pending":
      return (
        <>
          <Text fontSize="18px" textColor="white" textAlign="center">
            Envie seus documentos, para que nossa equipe faça a validação dos
            mesmos
          </Text>

          <Text padding={20} color={"white"} textAlign="center">
            Ao enviar esses documentos, nossa equipe poderá verificar sua
            identidade e garantir que você esteja apto a utilizar nossos
            serviços. Lembre-se de que a validação dos documentos é importante
            para sua segurança e a de outros usuários. Em caso de dúvidas, entre
            em contato com nossa equipe de suporte.
          </Text>
        </>
      );

    case "under-review":
      return (
        <>
          <Text fontSize="18px" textColor="white" textAlign="center">
            Seus documentos estão sendo analisados por nossa equipe!
          </Text>

          <Text padding={20} color={"white"} textAlign="center">
            Se tudo estiver certo, logo logo você irá desfrutar de todos os
            benefícios que um N1 driver pode ter.
          </Text>
        </>
      );
    case "reproved":
      return (
        <>
          <Text fontSize="18px" textColor="white" textAlign="center">
            Infelizmente sua análise foi rejeitada pela nossa equipe.
          </Text>

          <Text padding={20} color={"white"} textAlign="center">
            Verifique se seus documentos foram enviados corretamente e em caso
            de dúvida entre em contato conosco.
          </Text>
        </>
      );
    default:
      return (
        <>
          <Text fontSize="18px" textColor="white" textAlign="center">
            Envie seus documentos, para que nossa equipe faça a validação dos
            mesmos
          </Text>

          <Text padding={20} color={"white"} textAlign="center">
            Ao enviar esses documentos, nossa equipe poderá verificar sua
            identidade e garantir que você esteja apto a utilizar nossos
            serviços. Lembre-se de que a validação dos documentos é importante
            para sua segurança e a de outros usuários. Em caso de dúvidas, entre
            em contato com nossa equipe de suporte.
          </Text>
        </>
      );
  }
};

