import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import z from "zod";
import { useAuthContext } from "../../../context/auth";

const FormData = z.object({
  firstName: z.string({ required_error: "Campo obrigatório" }),
  lastName: z.string({ required_error: "Campo obrigatório" }),
  gender: z.string({ required_error: "Campo obrigatório" }),
  birthDate: z.string({ required_error: "Campo obrigatório" }),
  nationality: z.string({ required_error: "Campo obrigatório" }),
  address: z.object({
    postcode: z.string({ required_error: "Campo obrigatório" }),
    city: z.string({ required_error: "Campo obrigatório" }),
    uf: z
      .string({ required_error: "Campo obrigatório" })
      .length(2, "2 Caractéres"),
    neighborhood: z.string({ required_error: "Campo obrigatório" }),
    street: z.string({ required_error: "Campo obrigatório" }),
    houseNumber: z.string({ required_error: "Campo obrigatório" }),
  }),
  naturalness: z.object({
    state: z.string({ required_error: "Campo obrigatório" }),
    city: z.string({ required_error: "Campo obrigatório" }),
    uf: z
      .string({ required_error: "Campo obrigatório" })
      .length(2, "2 Caractéres"),
  }),
  documents: z.object({
    rg: z.string({ required_error: "Campo obrigatório" }),
    cpf: z.string({ required_error: "Campo obrigatório" }),
  }),
  email: z
    .string({ required_error: "Campo obrigatório" })
    .email("E-mail inválido"),
  phoneNumber: z.string({ required_error: "Campo obrigatório" }),
  operatorCode: z.string({ required_error: "Selecione o Recrutador" }),
});

type TCreateDriverForm = z.infer<typeof FormData>;

export const SignUpWithBonus = () => {
  const [nationality, setNationality] = useState("");
  const [operators, setOperators] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [isLoading, setIsloading] = useState<boolean>(false);
  const { signIn } = useAuthContext();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TCreateDriverForm>({
    resolver: zodResolver(FormData),
    defaultValues: {
      gender: "male",
    },
  });

  const onSubmit: SubmitHandler<TCreateDriverForm> = async (data) => {
    setIsloading(true);
    const date = new Date(data.birthDate).toISOString();
    axios
      .post(
        "https://api.n1car.com.br/api/v1/auth/operator/pre-registration",
        {
          driver: {
            personal: {
              firstName: data.firstName,
              lastName: data.lastName,
              birthDate: date,
              gender: data.gender,
              nationality: data.nationality,
              naturalness: {
                uf: data.naturalness.uf,
                city: data.naturalness.city,
              },
            },
            documents: {
              rg: data.documents.rg,
              cpf: data.documents.cpf,
            },
            address: {
              postcode: data.address.postcode,
              city: data.address.city,
              uf: data.address.uf,
              neighborhood: data.address.neighborhood,
              street: data.address.street,
              houseNumber: data.address.houseNumber,
            },
          },
          user: {
            contact: {
              email: data.email,
              phoneNumber: data.phoneNumber,
            },
          },
          operatorId: data.operatorCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          const { token, user, parent } = response.data;
          signIn(token, user, parent);
          setTimeout(() => {
            navigate(`/dashboard?isPregistered=true`);
          }, 1000);
          setIsloading(false);
          return;
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 201) {
            alert("Formulário enviado com sucesso!");
            return;
          }

          if (error.response.status === 409) {
            switch (error.response.data.internalError) {
              case "AUTH002":
                alert("Email ja cadastrado.");
                break;
              case "AUTH003":
                alert("Telefone ja cadastrado.");
                break;
              case "AUTH004":
                alert("RG ja cadastrado.");
                break;
              case "AUTH005":
                alert("CPF ja cadastrado.");
                break;
              default:
                alert("Erro ao cadastrar usuário");
            }
          }

          if (error.response.status !== 201 && error.response.status !== 409) {
            alert("Erro ao enviar formulário, tente novamente mais tarde.");
            return;
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        setIsloading(false);
      });
  };

  const handleGetOperatorId = async () => {
    const url = "http://api.n1car.com.br/api/v1/operators";

    const response = await axios.get(url);
    setOperators(response.data as { _id: string; name: string }[]);

    return response;
  };

  useEffect(() => {
    handleGetOperatorId();
  }, []);

  return (
    <Flex
      p={{ sm: 6, md: 10 }}
      flexDirection="column"
      maxW={1024}
      mx="auto"
      justify="center"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          onSubmit={() => {}}
          display="flex"
          flexDirection="column"
          gap={6}
          p={12}
          color="#fff"
          borderWidth={1}
          borderColor="#ffcc00"
        >
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Dados Pessoais
            </Text>
            <SimpleGrid
              borderWidth={1}
              p={4}
              borderColor="#ffcc00"
              columns={{ sm: 1, md: 2 }}
              gap={6}
            >
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <FormControl isInvalid={errors.firstName != null}>
                    <FormLabel>Nome</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Nome"
                    />
                    <FormErrorMessage>
                      {errors.firstName?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <FormControl isInvalid={errors.lastName != null}>
                    <FormLabel>Sobrenome</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Sobrenome"
                    />
                    <FormErrorMessage>
                      {errors.lastName?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="gender"
                render={({ field }) => (
                  <FormControl isInvalid={errors.gender != null}>
                    <FormLabel>Gênero</FormLabel>
                    <Select {...field}>
                      <option style={{ color: "black" }} value="male">
                        Masculino
                      </option>
                      <option style={{ color: "black" }} value="female">
                        Feminino
                      </option>
                      <option style={{ color: "black" }} value="non-binary">
                        Não-binário
                      </option>
                    </Select>
                    <FormErrorMessage>
                      {errors.gender?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="birthDate"
                render={({ field }) => (
                  <FormControl isInvalid={errors.birthDate != null}>
                    <FormLabel>Data de nascimento</FormLabel>
                    <Input
                      {...field}
                      style={{ colorScheme: "dark" }}
                      type="date"
                      focusBorderColor="#fff"
                      placeholder="Data de Nascimento"
                    />
                    <FormErrorMessage>
                      {errors.birthDate?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="nationality"
                render={({ field: { onChange, value } }) => (
                  <FormControl isInvalid={errors.nationality != null}>
                    <FormLabel>País</FormLabel>

                    <CountryDropdown
                      //@ts-expect-error
                      style={{
                        color: "#000",
                        fontSize: 20,
                        width: "100%",
                      }}
                      defaultOptionLabel="Selecione seu país"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        setNationality(e);
                      }}
                    />
                    <FormErrorMessage>
                      {errors.nationality?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="naturalness.state"
                render={({ field }) => (
                  <FormControl isInvalid={errors.naturalness?.state != null}>
                    <FormLabel>Estado</FormLabel>

                    <RegionDropdown
                      //@ts-expect-error
                      style={{
                        color: "#000",
                        fontSize: 20,
                        width: "100%",
                      }}
                      country={nationality}
                      defaultOptionLabel="Selecione seu estado"
                      {...field}
                    />
                    <FormErrorMessage>
                      {errors.naturalness?.state?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="naturalness.city"
                render={({ field }) => (
                  <FormControl isInvalid={errors.naturalness?.city != null}>
                    <FormLabel>Cidade</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Cidade"
                    />

                    <FormErrorMessage>
                      {errors.naturalness?.city?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="naturalness.uf"
                render={({ field }) => (
                  <FormControl isInvalid={errors.naturalness?.uf != null}>
                    <FormLabel>UF</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="UF"
                    />
                    <FormErrorMessage>
                      {errors.naturalness?.uf?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </SimpleGrid>
          </Box>

          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Documentos
            </Text>
            <SimpleGrid
              borderWidth={1}
              p={4}
              borderColor="#ffcc00"
              columns={{ sm: 1, md: 2 }}
              gap={6}
            >
              <FormControl isInvalid={Boolean(errors.documents?.rg)}>
                <FormLabel htmlFor="documents.rg">Número do RG</FormLabel>
                <Controller
                  control={control}
                  name="documents.rg"
                  render={({ field: { onChange, name, value } }) => (
                    <Input
                      name={name}
                      value={value}
                      onChange={onChange}
                      focusBorderColor="#fff"
                      placeholder="Insira o número do RG"
                    />
                  )}
                />
                <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.documents?.cpf)}>
                <FormLabel htmlFor="documents.cpf">Número do CPF</FormLabel>
                <Controller
                  control={control}
                  name="documents.cpf"
                  render={({ field: { onChange, name, value } }) => (
                    <PatternFormat
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="Insira o número do CPF"
                      customInput={Input}
                      focusBorderColor="#fff"
                      format="###.###.###-##"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.documents?.cpf?.message}
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Box>

          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Endereço
            </Text>
            <SimpleGrid
              borderWidth={1}
              p={4}
              borderColor="#ffcc00"
              columns={{ sm: 1, md: 2 }}
              gap={6}
            >
              <Controller
                control={control}
                name="address.city"
                render={({ field }) => (
                  <FormControl isInvalid={errors.address?.city != null}>
                    <FormLabel>Cidade</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Cidade"
                    />
                    <FormErrorMessage>
                      {errors.address?.city?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="address.neighborhood"
                render={({ field }) => (
                  <FormControl isInvalid={errors.address?.neighborhood != null}>
                    <FormLabel>Bairro</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Bairro"
                    />
                    <FormErrorMessage>
                      {errors.address?.neighborhood?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="address.street"
                render={({ field }) => (
                  <FormControl isInvalid={errors.address?.street != null}>
                    <FormLabel>Rua</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Rua"
                    />
                    <FormErrorMessage>
                      {errors.address?.street?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="address.houseNumber"
                render={({ field }) => (
                  <FormControl isInvalid={errors.address?.houseNumber != null}>
                    <FormLabel>Número</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Número"
                    />

                    <FormErrorMessage>
                      {errors.address?.houseNumber?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <FormControl
                isInvalid={Boolean(errors.address?.postcode != null)}
              >
                <FormLabel>CEP</FormLabel>
                <Controller
                  control={control}
                  name="address.postcode"
                  render={({ field: { onChange, name, value } }) => (
                    <PatternFormat
                      name={name}
                      value={value}
                      onChange={onChange}
                      customInput={Input}
                      focusBorderColor="#fff"
                      placeholder="CEP"
                      format="#####-###"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.address?.postcode?.message}
                </FormErrorMessage>
              </FormControl>

              <Controller
                control={control}
                name="address.uf"
                render={({ field }) => (
                  <FormControl isInvalid={errors.address?.uf != null}>
                    <FormLabel>UF</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="UF"
                    />
                    <FormErrorMessage>
                      {errors.address?.uf?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </SimpleGrid>
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Contatos
            </Text>
            <SimpleGrid
              borderWidth={1}
              p={4}
              borderColor="#ffcc00"
              columns={{ sm: 1, md: 2 }}
              gap={6}
            >
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <FormControl isInvalid={errors.email != null}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Email"
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />
              <FormControl isInvalid={Boolean(errors.phoneNumber)}>
                <FormLabel htmlFor="phoneNumber">Telefone</FormLabel>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <Input
                      {...field}
                      focusBorderColor="#fff"
                      placeholder="Telefone"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.phoneNumber?.message}
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Código do Operador
            </Text>
            <SimpleGrid
              borderWidth={1}
              p={4}
              borderColor="#ffcc00"
              columns={{ sm: 1, md: 2 }}
              gap={6}
            >
              <FormControl>
                <Controller
                  control={control}
                  name="operatorCode"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.operatorCode != null}>
                      <FormLabel>Operador(a)</FormLabel>
                      <Select {...field}>
                        {operators &&
                          operators.map((item, idx) => (
                            <option
                              key={idx}
                              style={{ color: "black" }}
                              value={item._id}
                            >
                              {item.name}
                            </option>
                          ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.operatorCode?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <FormErrorMessage>
                  {errors.operatorCode?.message}
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Box>
          {!isLoading ? (
            <Button
              _hover={{ bgColor: "#ffcc80" }}
              bgColor="#ffcc00"
              p={4}
              py={6}
              alignItems="center"
              justifyContent="center"
              type="submit"
              title="Continuar"
            >
              Continuar
            </Button>
          ) : (
            <Button
              bgColor="#ffcc00"
              p={4}
              py={6}
              alignItems="center"
              justifyContent="center"
            >
              <HStack spacing={5}>
                <Text>Enviando...</Text>
                <CircularProgress isIndeterminate size="30px" color="black" />
              </HStack>
            </Button>
          )}
        </Box>
      </form>
    </Flex>
  );
};
