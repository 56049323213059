import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { FrequentlyAskedQuestions } from "./modals";

export const Help = () => {
  const [lessThan1048] = useMediaQuery(["(max-width: 1048px)"]);

  return (
    <Flex
      w={lessThan1048 ? "90%" : "60%"}
      marginY="2rem"
      flexDir="column"
      color="white"
      fontFamily="Poppins"
    >
      <Text fontSize="3xl" fontWeight="bold" marginBottom="1rem" textColor="#FDD835">
        Página de ajuda ao Usuário e ao Motorista
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginBottom="1rem" textColor="#FDD835">
        Perguntas Frequentes (FAQs)
      </Text>

      <Box marginTop="1rem">
        <FrequentlyAskedQuestions />
      </Box>


    </Flex>
  );
};
