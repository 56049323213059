import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";

import Security from "../../assets/images/securityImage.png";

export const AvaliationCard = () => {
  const [lessThan524] = useMediaQuery(["(max-width: 1000px)"]);

  return (
    <Flex
      px={lessThan524 ? "2rem" : "4rem"}
      py={lessThan524 ? "2rem" : "12rem"}
      align={"center"}
      id="seguranca"
      position={"relative"}
    >
      <Image
        w={"60%"}
        src={Security}
        position={"absolute"}
        zIndex={1}
        right={"4rem"}
      />

      <Box
        w={"100%"}
        h={"100%"}
        zIndex={2}
        position={"absolute"}
        bg={"#00000096"}
        top={0}
        right={0}
      />

      <Box w={lessThan524 ? "100%" : "40%"} zIndex={3}>
        <Text
          color="#DCBC2E"
          fontSize={lessThan524 ? "1.4rem" : "2.5rem"}
          textAlign={"center"}
          fontWeight={"light"}
          mb={"2rem"}
          w={lessThan524 ? "100%" : "80%"}
        >
          SEGURANÇA
        </Text>

        <Text
          color={"#fff"}
          fontSize={lessThan524 ? "1rem" : "1.4rem"}
          textAlign={lessThan524 ? "start" : "center"}
        >
          Este item é uma prioridade máxima para a N1 Car com foco na segurança
          tanto dos motoristas quanto dos passageiros.
        </Text>
      </Box>
    </Flex>
  );
};
