import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { useAuthContext } from "../../context/auth";
import { AuthRepository } from "../../services/auth";
import { PatternFormat } from "react-number-format";

const FormData = z
  .object({
    email: z
      .string({ required_error: "Campo obrigatório" })
      .email("E-mail inválido"),
    phoneNumber: z.string({ required_error: "Campo obrigatório" }),
    password: z.string(),
    confirmPassword: z.string(),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: "Senhas não são iguais",
    path: ["confirmPassword"],
  });

type ISignUpForm = z.infer<typeof FormData>;

export const SignUpUser = () => {
  const { signIn } = useAuthContext();
  const { driverId } = useParams();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ISignUpForm>({
    resolver: zodResolver(FormData),
  });

  const onSubmit: SubmitHandler<ISignUpForm> = async (data) => {
    setIsLoading(true);
    try {
      const response = await AuthRepository.signUp({
        contact: {
          email: data.email,
          phoneNumber: data.phoneNumber,
        },
        parent: {
          id: driverId!,
          type: "driver",
        },
        password: data.password,
      });

      if (response) {
        signIn(response.token, response.user, response.parent);
        navigate("/dashboard");
      }
    } catch (error: any) {
      alert("Houve um erro ao criar conta. Por favor tente mais tarde!");
    } finally {
      setIsLoading(false);
    }
  };

  if (!driverId) {
    navigate("/sign-up");
  }

  return (
    <Flex
      p={{ sm: 6, md: 10 }}
      flexDirection="column"
      w="100%"
      maxW={720}
      mx="auto"
      justify="center"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          onSubmit={() => {}}
          display="flex"
          flexDirection="column"
          gap={6}
          p={6}
          color="#fff"
          borderWidth={{ sm: 0, md: 1 }}
          borderColor="#ffcc00"
          w={"full"}
        >
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <FormControl isInvalid={errors.email != null}>
                <FormLabel>Email</FormLabel>
                <Input {...field} focusBorderColor="#fff" placeholder="Email" />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <FormControl isInvalid={Boolean(errors.phoneNumber)}>
            <FormLabel htmlFor="phoneNumber">Telefone</FormLabel>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <Input
                  {...field}
                  focusBorderColor="#fff"
                  placeholder="Telefone"
                />
              )}
            />
            <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
          </FormControl>

          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <FormControl isInvalid={errors.password != null}>
                <FormLabel>Senha</FormLabel>
                <Input
                  {...field}
                  type="password"
                  focusBorderColor="#fff"
                  placeholder="Senha"
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <FormControl isInvalid={errors.confirmPassword != null}>
                <FormLabel>Confirmar Senha</FormLabel>
                <Input
                  {...field}
                  type="password"
                  focusBorderColor="#fff"
                  placeholder="Confirmar Senha"
                />
                <FormErrorMessage>
                  {errors.confirmPassword?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <Button
            _hover={{ bgColor: "#ffcc80" }}
            bgColor="#ffcc00"
            p={4}
            alignItems="center"
            justifyContent="center"
            type="submit"
            title="Continuar"
            isLoading={isLoading}
            loadingText="Finalizando"
          >
            Finalizar Cadastro
          </Button>
        </Box>
      </form>
    </Flex>
  );
};
