import { Flex, Box, FlexProps } from "@chakra-ui/react";
import { Header } from "../Components/header";
import { Footer } from "../Components/footer";
import { DetailCard } from "../Components/main-page/detail-card";
import { Goals } from "../Components/main-page/goals";
import { WelcomeCard } from "../Components/main-page/welcome-card";
import { OfferCard } from "../Components/main-page/offer-card";
import { PaymentCard } from "../Components/main-page/payment-card";
import { ComunicationCard } from "../Components/main-page/comunication-card";
import { AvaliationCard } from "../Components/main-page/avaliation-card";
import { WomanCard } from "../Components/main-page/woman-card";
import { AppAdvantages } from "../Components/main-page/app-advantages";
import { DownloadCard } from "../Components/main-page/download-card";
import Contacts from "../Components/main-page/contacts-main";

export function Home() {
  return (
    <Flex {...styles.container()}>
      <Header />
      <Box>
        <DetailCard />
        <WelcomeCard />
        <Goals />
        <OfferCard />
        <PaymentCard />
        <ComunicationCard />
        <AvaliationCard />
        <WomanCard />
        <AppAdvantages />
        <DownloadCard />
        <Contacts />
      </Box>
      <Footer />
    </Flex>
  );
}

const styles = {
  container: (): FlexProps => ({
    h: "100vh",
    flexDir: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    bg: "#181818",
    // __css: {
    //   backgroundColor: "black",
    //   backgroundImage: "linear-gradient(60deg, #181818, #000000)",
    // }
  }),
};
