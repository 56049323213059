import { Flex, FlexProps } from "@chakra-ui/react";
import { Header } from "../Components/header";
import { Footer } from "../Components/footer";
import { SignUpUser } from "../Components/main-page/sign-up-user";

export default function SignUpUserPage() {
  return (
    <Flex {...styles.container()}>
      <Header />
      <SignUpUser />
      <Footer />
    </Flex>
  );
}

const styles = {
  container: (): FlexProps => ({
    h: "100vh",
    flexDir: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    bg: "#181818",
  }),
};
