import { Box, Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { TDocumentType, TUploadedImage } from "./main-page/dashboard";

interface DropzoneProps {
  setUploadedImages: React.Dispatch<React.SetStateAction<TUploadedImage[]>>;
  type: TDocumentType;
  uploadText: string;
}

export const Dropzone = ({
  setUploadedImages,
  uploadText,
  type,
}: DropzoneProps) => {
  const [image, setImage] = useState("");

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const fileSize = file.size / (1024 * 1024);
    console.log("fileSize", fileSize);
    if (fileSize > 5) return alert("Arquivo é superior a 5mb!");
    if (image) {
      setUploadedImages((prev) => prev.filter((file) => file.type !== type));
    }
    setImage(URL.createObjectURL(file));
    setUploadedImages((prev) => [...prev, { type, file }]);
  };

  const dropzoneStyles: React.CSSProperties = {
    border: "2px dashed #FDD853",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    margin: "20px",
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? "active" : ""}`}
      style={dropzoneStyles}
    >
      <Box>
        <Text color="yellow.400" fontSize="20px">
          {uploadText}
        </Text>
        <input {...getInputProps()} />
        {image ? (
          <img src={image} alt="Uploaded" style={styles.uploadedImage} />
        ) : (
          <Box mt="20px">
            <Text color="white">
              {isDragActive
                ? "Solte os arquivos aqui..."
                : "Arraste e solte os arquivos aqui, ou clique para selecionar arquivos"}
            </Text>
          </Box>
        )}
      </Box>
    </div>
  );
};

const styles = {
  uploadedImage: {
    maxWidth: "100%",
    maxHeight: "120vh",
  },
};
