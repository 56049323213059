import { TPrivacyLists } from "../@types/privacy"

export const PRIVACY_POLICIES: TPrivacyLists = {
  texts: {
    firstText: "A N1CAR TECNOLOGIA E AGENCIAMENTO DE SERVICOS LTDA (N1CAR) opera o site https://n1car.com.br/ (doravante – “Site” ou “Aplicativo”), cujo objetivo é fornecer informações sobre como se tornar um usuário ou parceiro de negócios da N1Car, sobre soluções, serviços e produtos da N1Car para parceiros de negócios, bem como para processar os formulários de inscrição preenchidos, além de informar sobre os nossos potenciais parceiros de negócios e/ou usuários dos produtos da N1Car para parceiros de negócios, caso você decida preencher o nosso formulário no Site.",
    secondText: "Bem-vindo aos Termos de privacidade da N1Car. Antes de usar nossos serviços, pedimos que leia atentamente este documento. Ao acessar e utilizar nosso site e serviços, você concorda com os termos e condições aqui. Se você não concorda com estes termos, solicitamos que não utilize nossos serviços.",
    thirdText: "Ao utilizar nossos serviços, você declara e garante que possui a capacidade legal de celebrar um contrato vinculante de acordo com as leis civis e penais em sua jurisdição. Caso não possua tal capacidade, você não está autorizado a utilizar nossos serviços.",
    fourthText: "Na N1Car, usamos os dados dos candidatos no processo de recrutamento, bem como dos usuários finais, para várias finalidades importantes. Aqui estão algumas delas: ",
    fifthText: "Na N1Car, podemos permitir que outras empresas nos ajudem a entender melhor o público que visita nosso site e melhora nossos serviços. Para isso, usamos serviços analíticos e medições de público online. Esses serviços analíticos nos fornecem informações importantes sobre como as pessoas interagem com nosso site e nossos anúncios. Assim, podemos oferecer anúncios relevantes para você na internet e também acompanhar o desempenho desses anúncios. Para fazer isso, essas empresas usam algumas tecnologias, como SDKs, web beacons, cookies e outros. Essas tecnologias ajudam a identificar seu dispositivo, como seu computador ou celular, quando você acessa nosso site, incluindo a página de cadastramento da N1Car. É importante lembrar que essas tecnologias são usadas apenas para fins de análise e melhoria para nossos serviços. Não coletamos informações pessoais fornecidas através dessas tecnologias. Se quiser saber mais detalhes sobre essas tecnologias e as empresas que trabalham conosco nesse sentido, você pode consultar a nossa Política de Cookies. Lá, você encontrará informações mais específicas sobre como lidar com essas questões e como proteger sua privacidade. Nosso objetivo é sempre proporcionar a melhor experiência possível aos nossos usuários, incluindo a exibição de anúncios relevantes e interessantes para você. Estamos comprometidos em garantir a segurança e a transparência em todas as nossas práticas, para que você possa utilizar nossos serviços com tranquilidade e confiança.",
    sixthText: "O titular de dados pessoais é “pessoa natural a quem se referem os dados pessoais que são objeto de tratamento” (Art.5º, V, LGPD), que tem assegurada a titularidade de seus dados pessoais e garantidos os direitos fundamentais de liberdade, de intimidade e de privacidade, nos termos desta Lei Geral de Proteção de Dados (LGPD). O(A) USUÁRIO(A) autoriza, expressamente, que a N1Car utilize os dados pessoais e dados pessoais sensíveis listados neste termo para as seguintes finalidades: a) Permitir que a N1Car identifique e entre em contato com o(a) USUÁRIO(A), em razão das atividades exercidas e usufruídas; b) Para cumprimento, pela N1Car, de obrigações impostas por órgãos de fiscalização; c) Quando necessário, para atender aos interesses legítimos da N1Car ou de terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais;",
    seventhText: "A N1Car se responsabiliza por manter medidas de segurança técnicas e administrativas suficientes a proteger os dados pessoais do(a) USUÁRIO(A), comunicando-o (a) caso aconteça qualquer incidente de segurança que possa acarretar risco ou dano relevante, conforme o artigo 48 da Lei 13.709/2018. Fica permitido à N1Car manter e utilizar os dados pessoais do(a) USUÁRIO(A) durante todo o período contratualmente firmado, para as finalidades relacionadas neste termo e, ainda, após o término da contratação para cumprimento da obrigação legal ou impostas por órgãos de fiscalização, nos termos do artigo 16 da Lei 13.709/2018; ",
    eighthText: "Fica permitido à N1Car manter e utilizar os dados pessoais do(a) USUÁRIO(A) durante todo o período de duração comercial existente.",
    ninethText: "O(A) USUÁRIO(A) fica ciente de que a N1Car deverá permanecer com os seus dados pelo período de duração de toda a relação contratual e pelo prazo prescricional.",
  }
}

export const PRIVACY_LISTS: TPrivacyLists = {
  firstList: {
    title: "Definições",
    one: '"Nós, "nosso" e "N1Car" referem-se à empresa N1Car e suas subsidiárias, se houver."',
    two: '"Usuário", "você" e "seu" referem-se a qualquer pessoa física, dentro do Brasil, que acesse e utilize nossos serviços.',
    three: '"Serviços" englobam todo e qualquer serviço fornecido pela N1Car, incluindo, mas não se limitando a, intermediação de mobilidade urbana, não se assemelhando a compartilhamento de caronas, aluguel de veículos e afins.',
  },

  secondList: {
    title: "Elegibilidade ",
  },

  thirdList: {
    title: "Aplicação",
  },
  fourthList: {
    title: "Coleta dos dados",
    one: "Dados de localização (motorista): Coletamos dados de localização precisa ou aproximada dos dispositivos móveis dos motoristas parceiros quando o app da N1 Car Driver está sendo executado em primeiro plano  ou em segundo plano.",
    two: "Dados de localização (passageiros). Coletamos informações de localização precisa ou aproximada dos dispositivos móveis de passageiros quando eles nos dão permissão pelas configurações do dispositivo.",
    three: "Uso de dados de localização: identificar partidas de usuários (passageiros e motoristas), calcular a previsão de chegada e acompanhar o andamento das viagens, designar motoristas disponíveis aos passageiros que solicitam serviços, inclusive com base em dados pessoais, como localização e proximidade de outros usuários.",
    four: "Informações da conta: coletamos dados quando os usuários criam ou atualizam as contas da N1. Isso inclui nome e sobrenome, e-mail, número de telefone, nome de usuário e senha, endereço, foto do perfil, informações de pagamento ou dados bancários (incluindo informações relacionadas à confirmação de pagamento), configurações do usuário (incluindo configurações de acessibilidade) e informações do programa do app de parceiro." +
      "Entre esses dados, também podem constar informações do veículo.",
    five: "Documentos de confirmação de identidade e fotos: Isso inclui documentos de identificação emitidos pelo governo, como carteira de habilitação ou passaporte (que podem conter fotos e números de identificação, data de nascimento e gênero), e fotos enviadas pelo usuário, como selfies e fotos de perfil.",
  },
  fifthList: {
    title: "Uso dos dados.",
    one: "Criar/atualizar contas.",
    two: "Viabilizar viagens, entregas e outros serviços e recursos, por exemplo: \n" +
      "Uso de dados de localização para identificar partidas de usuários, calcular a previsão de chegada e acompanhar o andamento das viagens ou entregas.\n" +
      "Ativar recursos que envolvam o compartilhamento de dados, como compartilhar o nome do motorista parceiro e as informações do veículo com os usuários para facilitar o encontro entre passageiros e motoristas, ou recursos que permitem o compartilhamento da previsão de chegada e a divisão do valor das viagens.\n" +
      "Designar motoristas parceiros disponíveis aos passageiros que solicitam serviços, inclusive com base em dados pessoais, como localização e proximidade de outros usuários, configurações/ preferências do usuário(como destinos preferidos) e dados não pessoais, como o tipo de veículo solicitado.\n",
    three: "Gerar recibos, mostrar atualizações de viagens ou entregas aos usuários e informá-los sobre alterações em nossos termos, serviços ou políticas.",
    four: "Verificar as contas, a identidade ou a conformidade dos usuários com os requisitos de segurança. Por exemplo, verificamos os antecedentes dos motoristas e entregadores parceiros (incluindo antecedentes criminais, quando exigido ou permitido por lei) para confirmar suas identidades e qualificação para fornecer transporte ou entrega",
    five: "Usar conta, dispositivo, localização, utilização, transações, operadora de rede sem fio e outros dados, incluindo comunicações entre usuários e metadados, para prevenir, detectar e combater fraudes, inclusive por usuários convidados.",

  },
  sixthList: {
    title: "Direito de revogação",
  },
}