import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { CookiesPage } from "../pages/cookies";
import { PrivacyPage } from "../pages/privacy";
import { TermsPage } from "../pages/terms";
import { HelpPage } from "../pages/help";
import { Dashboards } from "../pages/dashboard";
import SupportPage from "../pages/support";
import SignInPage from "../pages/sign-in";
import SignUpPage from "../pages/sign-up";
import SignUpUserPage from "../pages/sign-up-user";
import { SignUpWithBonus } from "../Components/main-page/bonus/sign-up-wiht-bonus";
import { OpitionalSignUp } from "../pages/optional-sign-up";

export const PagesRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<CookiesPage />} path="/cookies" />
        <Route element={<PrivacyPage />} path="/privacidade" />
        <Route element={<TermsPage />} path="/termos" />
        <Route element={<HelpPage />} path="/help" />
        <Route element={<SupportPage />} path="/suporte" />
        <Route element={<SignInPage />} path="/sign-in" />
        <Route element={<SignUpPage />} path="/sign-up" />
        <Route element={<SignUpWithBonus />} path="/operator/sign-up" />
        <Route element={<OpitionalSignUp />} path="/optional/sign-up" />
        <Route element={<SignUpUserPage />} path="/user/sign-up/:driverId" />
        <Route element={<Dashboards />} path="/dashboard" />
      </Routes>
    </BrowserRouter>
  );
};
