import {
  Flex,
  Grid,
  Heading,
  Image,
  useMediaQuery,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import logo from "../../assets/logo.png";

export const OfferCard = () => {
  const [lessthan524] = useMediaQuery(["(max-width: 1000px)"]);

  return (
    <Grid
      gridTemplateColumns={lessthan524 ? "1fr" : "4fr 2fr"}
      px={"2rem"}
      id="objetivos"
      py={lessthan524 ? "1rem" : "5rem"}
      bg="#000"
    >
      <Flex justifyContent={"center"} flexDir="column" alignItems="center">
        <Heading
          color="#DCBC2E"
          marginTop={lessthan524 ? "1rem" : "0rem"}
          fontFamily={"Montserrat"}
          fontWeight={"light"}
          marginBottom={"3rem"}
          fontSize={lessthan524 ? "1.6rem" : "2.5rem"}
          textAlign={lessthan524 ? "center" : "start"}
          // w={lessthan524 ? "80%" : "50%"}
        >
          OBJETIVOS DA N1
        </Heading>

        <UnorderedList w={"80%"}>
          <ListItem
            mb={"1.5rem"}
            color={"#fff"}
            fontSize={lessthan524 ? "1rem" : "1.4rem"}
          >
            A N1 Car tem como objetivo profissionalizar o segmento de transporte
            por aplicativo no Brasil, estabelecendo novos padrões para
            treinamento de motoristas e qualidade geral do serviço prestado.
          </ListItem>

          <ListItem
            mb={"1.5rem"}
            color={"#fff"}
            fontSize={lessthan524 ? "1rem" : "1.4rem"}
          >
            A empresa visa um sistema de transporte confiável que beneficie tanto
            os motoristas, quanto os passageiros.
          </ListItem>

          <ListItem
            mb={"1.5rem"}
            color={"#fff"}
            fontSize={lessthan524 ? "1rem" : "1.4rem"}
          >
            Temos o compromisso de repassar o valor integral da viagem para o Motorista.
          </ListItem>

          <ListItem
            mb={"1.5rem"}
            color={"#fff"}
            fontSize={lessthan524 ? "1rem" : "1.4rem"}
          >
            Na N1 Car o Motorista ganha mais e o Passageiro pagará
            menos!
          </ListItem>
        </UnorderedList>
      </Flex>

      <Flex
        justifyContent={"center"}
        alignItems="center"
        display={lessthan524 ? "none" : "flex"}
      >
        <Image h={"50%"} src={logo} />
      </Flex>
    </Grid>
  );
};
