import { Flex, FlexProps } from "@chakra-ui/react";
import { Header } from "../Components/header";
import { Help } from "../Components/help";
import { Footer } from "../Components/footer";

export function HelpPage() {
    return (
        <Flex {...styles.container()}>
            <Header />
            <Flex justifyContent="center">
                <Help />
            </Flex>
            <Footer />
        </Flex>
    );
}

const styles = {
    container: (): FlexProps => ({
        h: "100vh",
        flexDir: "column",
        justifyContent: "space-between",
        overflowY: "auto",
        bg: "#181818",
    }),
};
