import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import Bike from "../../assets/images/bike.png";
import Dog from "../../assets/images/dog.png";

export const AppAdvantages = () => {
  const [lessthan524] = useMediaQuery(["(max-width: 1000px)"]);

  return (
    <Flex
      direction={"column"}
      align={"center"}
      id="sustentabilidade"
      p={lessthan524 ? "2rem" : "4rem"}
      bg={"#000"}
    >
      <Heading
        color="#DCBC2E"
        fontWeight={"300"}
        fontFamily={"Montserrat"}
        mb={"3rem"}
        textAlign={lessthan524 ? "center" : "start"}
        fontSize={lessthan524 ? "1.6rem" : "2.5rem"}
      >
        SUSTENTABILIDADE E INCLUSÃO
      </Heading>

      <Flex direction={lessthan524 ? "column" : "row"} align={"center"}>
        <Box p={"2rem"} px={lessthan524 ? "1rem" : "4rem"} flex={1}>
          <Flex direction={"column"} align={"center"} mb={"1rem"}>
            <Image
              src={Dog}
              h={"10rem"}
              w={"10rem"}
              objectFit={"cover"}
              borderRadius={"100%"}
            />

            <Text
              color="#DCBC2E"
              fontFamily={"Montserrat"} 
              fontWeight={"bold"}
              bg={"#000"}
              p={"0.2rem"}
              borderRadius={"1rem"}
              marginTop={"-1.5rem"}
              textAlign="center"
              fontSize={"1.5rem"}
            >
              Pet Friendly
            </Text>
          </Flex>

          <Text
            color="#fff"
            fontSize={"1.3rem"}
            textAlign={lessthan524 ? "center" : "start"}
          >
            A N1 Car busca trazer inclusão para os donos de pets, atendendo às
            necessidades dos passageiros que desejam viajar com seus animais e
            ter compromisso em transportar os animais de estimação prezando a
            segurança e bem estar de todos.
          </Text>
        </Box>

        <Box
          p={"2rem"}
          borderLeft={`${lessthan524 ? "0" : "1"}px solid #DCBC2E`}
          px={lessthan524 ? "1rem" : "4rem"}
          flex={1}
        >
          <Flex direction={"column"} align={"center"} mb={"1rem"}>
            <Image
              src={Bike}
              h={"10rem"}
              w={"10rem"}
              objectFit={"cover"}
              borderRadius={"100%"}
            />

            <Text
              color="#DCBC2E"
              fontFamily={"Montserrat"}
              fontWeight={"bold"}
              bg={"#000"}
              p={"0.2rem"}
              borderRadius={"1rem"}
              marginTop={"-1.5rem"}
              fontSize={"1.5rem"}
              textAlign="center"
            >
              Bike Friendly
            </Text>
          </Flex>

          <Text
            color="#FFF"
            fontSize={"1.3rem"}
            textAlign={lessthan524 ? "center" : "start"}
          >
            A N1 Car abraça a inclusão ao ser amigável com bicicletas,
            respeitando o trânsito e promovendo uma boa relação com os ciclistas
            que utilizam modos alternativos de transporte
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
