import React from "react";
import {
  Flex,
  ListItem,
  OrderedList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import { PRIVACY_POLICIES, PRIVACY_LISTS } from "../../mocks/privacy";

export const Privacy = () => {
  const [lessThan1048] = useMediaQuery(["(max-width: 1048px)"]);

  return (
    <Flex
      w={lessThan1048 ? "90%" : "60%"}
      marginY="2rem"
      flexDir="column"
      color="white"
      fontFamily="Poppins"
    >
      <Text
        fontSize="3xl"
        fontWeight="bold"
        marginBottom="1rem"
        textColor="#FDD835"
      >
        POLÍTICA DE PRIVACIDADE
      </Text>
      <Text
        fontSize="xl"
        fontWeight="bold"
        marginBottom="0.5rem"
        textColor="#FDD835"
      >
        N1CAR TECNOLOGIA E AGENCIAMENTO DE SERVICOS LTDA
      </Text>
      <Text fontSize="0.9rem">{PRIVACY_POLICIES.texts.firstText}</Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.secondText}
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.thirdText}
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        {PRIVACY_LISTS.firstList.title}
      </Text>
      <OrderedList marginTop="0.5rem" fontSize="0.8rem">
        {Object.entries(PRIVACY_LISTS.firstList)
          .map(([key, value]) => (
            <ListItem key={key}>{value}</ListItem>
          ))
          .slice(1)}
      </OrderedList>
      <Text
        fontSize="xl"
        fontWeight="bold"
        marginTop="1rem"
        textColor="#FDD835"
      >
        {PRIVACY_LISTS.secondList.title}
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.thirdText}
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.fifthText}
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.sixthText}
      </Text>

      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        {PRIVACY_LISTS.fourthList.title}
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        Dados que coletamos dos usuários
      </Text>
      <OrderedList marginTop="0.5rem" fontSize="0.8rem">
        {Object.entries(PRIVACY_LISTS.fourthList)
          .map(([key, value]) => (
            <ListItem key={key}>{value}</ListItem>
          ))
          .slice(1, 8)}
      </OrderedList>

      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        Uso dos dados
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
      Como usamos os dados que coletamos dos usuários
      </Text>
      <OrderedList marginTop="0.5rem" fontSize="0.8rem">
        {Object.entries(PRIVACY_LISTS.fifthList)
          .map(([key, value]) => (
            <ListItem key={key}>{value}</ListItem>
          ))
          .slice(1, 8)}
      </OrderedList>
      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        Cookies e outros
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.fifthText}
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        Tratamento de dados
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.sixthText}
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        Da responsabilidade
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.seventhText}
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        Término do tratamento de dados
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.eighthText}
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        Revogação de consentimento
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        O(A) USUÁRIO(A) poderá revogar seu consentimento, a qualquer tempo, por
        carta eletrônica ou escrita, conforme o parágrafo 5º do artigo 8º combinado
        com o inciso VI do caput do artigo 18 e com o artigo 16 da Lei 13.709/2018.
      </Text>
      <Text fontSize="xl" fontWeight="bold" marginY="1rem" textColor="#FDD835">
        Tempo de permanência
      </Text>
      <Text marginTop="0.5rem" fontSize="0.9rem">
        {PRIVACY_POLICIES.texts.ninethText}
      </Text>
    </Flex>
  );
};