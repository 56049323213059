import { Box, Flex, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useMediaQuery,} from "@chakra-ui/react";

import { COOKIES_POLICIES } from "../../../mocks/cookies";

export function UsedTechnologies() {
  const [lessThan756] = useMediaQuery(["(max-width: 756px)"]);
  return (
    <Flex flexDir="column" alignItems="flex-start">
      <Accordion allowToggle w="100%">
        <AccordionItem>
          <h2>
            <AccordionButton  _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES.cookies.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <Text>{COOKIES_POLICIES.cookies.description}</Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES.pixels.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES.pixels.description}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES.localStorage.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES.localStorage.description}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: '#FDD835', color: '#000'}}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {COOKIES_POLICIES.otherTechnologies.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            {COOKIES_POLICIES.otherTechnologies.description}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}
