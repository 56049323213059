import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import WorldMap from "../../assets/images/worldmap.png";
import Location from "../../assets/images/loc.png";

export const DownloadCard = () => {
  const [lessThan524] = useMediaQuery(["(max-width: 1000px)"]);

  return (
    <Box py={"4rem"} bg={"#000"} id="comunicacao">
      <Heading
        color="#DCBC2E"
        fontWeight={"300"}
        fontFamily={"Montserrat"}
        mb={lessThan524 ? "0rem" : "3rem"}
        textAlign={"center"}
      >
        COMUNICAÇÃO
      </Heading>

      <Flex direction={lessThan524 ? "column" : "row"} align={"center"}>
        <Box flex={1} p={"2rem"} display={lessThan524 ? "none" : "block"}>
          <Image src={WorldMap} />
        </Box>

        <Flex flex={1} p={"2rem"} direction={"column"} align={"center"}>
          <Image src={Location} h={"3rem"} w={"3rem"} mb={"2rem"} />

          <Text
            color="#fff"
            fontSize={"1.4rem"}
            textAlign={lessThan524 ? "center" : "start"}
          >
            A N1 Car oferece um recurso de mensagens com tradução simultânea em
            mais de 245 idiomas, facilitando a comunicação entre motoristas e
            passageiros de diferentes origens.
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
