import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";

import { Link, useLocation } from "react-router-dom";

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const links = [
  { name: "Metas", link: "metas" },
  { name: "Objetivos", link: "objetivos" },
  { name: "Compromisso", link: "compromisso" },
  { name: "Assistência", link: "assistencia" },
  { name: "Segurança", link: "seguranca" },
  { name: "Contato", link: "contato" },
  { name: "Suporte", link: "suporte" },
]

export function Sidebar(props: Props) {
  const { isOpen, onClose } = props;

  const location = useLocation();

  return (
    <Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />

        <DrawerContent bg="#181818">
          <DrawerCloseButton color="white" marginTop="7.5%" />

          <DrawerHeader>
            {/* <Flex h="4rem" alignItems="center"> */}
            {/* <Image src={Logo} h="3rem" /> */}
            <Text fontSize="2rem" color="white" paddingX="0.5rem">
              Menu
            </Text>
            {/* </Flex> */}
          </DrawerHeader>

          <Flex>
            <DrawerBody>
              {links.map((link, idx) => (
                <div key={idx}>
                  {location.pathname === "/termos" ? (
                    <div>
                      <Link to={`/#${link.link}`}>
                        <Button bg="#181818" colorScheme="blackAlpha">
                          {link.name}
                        </Button>
                      </Link>
                    </div>
                  )
                    : (
                      <div>
                        <a
                          key={link.name}
                          href={`#${link.link}`}
                          onClick={(e) => {
                            let hero = document.getElementById(link.link);
                            e.preventDefault(); // Stop Page Reloading
                            hero &&
                              hero.scrollIntoView({
                                behavior: "smooth",
                              });
                          }}
                        >
                          <Button bg="#181818" colorScheme="blackAlpha">
                            {link.name}
                          </Button>
                        </a>
                      </div>
                    )}
                </div>
              ))}

              <div>
                <Link to="/termos">
                  <Button bg="#181818" colorScheme="blackAlpha">
                    Termos
                  </Button>
                </Link>
              </div>

              <div>
                <Link to="/privacidade">
                  <Button bg="#181818" colorScheme="blackAlpha">
                    Privacidade
                  </Button>
                </Link>
              </div>
            </DrawerBody>
          </Flex>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

