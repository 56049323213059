import { Flex, Text } from "@chakra-ui/react";
import { RiFacebookBoxFill, RiInstagramFill } from "react-icons/ri";

export const Footer = () => {

  return (
    <Flex
      justifyContent="center"
      h={"52px"}
      p={"1rem"}
      alignItems="center"
      bg={"#DCBC2E"}
    >
      <Text color="#181818">
        N1 Car &copy; {new Date().getFullYear()} &bull; Todos os direitos
        reservados
      </Text>
      <Flex pl={"30"} gap={"5"}>
        <a
          href="https://www.instagram.com/n1caroficial/"
          style={{ fontSize: "25px", color: "black" }}
        >
          <RiInstagramFill>Instagram</RiInstagramFill>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61550529987627&mibextid=LQQJ4d"
          style={{ fontSize: "25px", color: "black" }}
        >
          <RiFacebookBoxFill color="black">Facebook</RiFacebookBoxFill>
        </a>
      </Flex>
    </Flex>
  );
};
