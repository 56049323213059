import { Flex, FlexProps } from "@chakra-ui/react";
import { SignInPage } from "../Components/main-page/sign-in";
import { Footer } from "../Components/footer";

export default function SignInPages() {
  return (
    <Flex {...styles.container()}>
      <Flex justifyContent="center">
        <SignInPage />
      </Flex>
      <Footer />
    </Flex>
  );
}

const styles = {
  container: (): FlexProps => ({
    h: "100vh",
    flexDir: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    bg: "#181818",
  }),
};
