import { THelpLists } from "../@types/help";

export const HELP_MODALS: THelpLists = {
  signature: {
    title: "ASSINATURA",
    description1:
      "COMO É FEITA A ASSINATURA DO APLICATIVO: A pessoa fará uma assinatura mensal para se tornar um motorista cadastrado do app.",
    description2:
      "PARA QUE SERVE: Com a assinatura o motorista receberá o valor total da corrida que ele aceitar.",
    description3:
      "COMO ASSINAR: O usuário terá que assinar um plano para poder ser um de nossos motoristas.",
    description4:
      "POSSO CANCELAR A QUALQUER MOMENTO: Sim, caso a pessoa não deseje ser mais um motorista do app, ele poderá cancelar a assinatura a qualquer momento.",
    description5:
      "RENOVAÇÃO: A renovação da assinatura será automática caso o motorista pague via cartão, porém, se for feita a escolha de outra forma de pagamento, basta que o mesmo refaça os passos que realizou na primeira vez que fez a assinatura.",
  },

  trip: {
    title: "CORRIDA",
    description1:
      "COMO FAZER: Abra o aplicativo, insira o local de partida e destino, confirme a forma de pagamento e solicite a corrida.",
    description2:
      "COMO SABER SE O MOTORISTA ACEITOU MINHA CORRIDA: O aplicativo enviará uma notificação informando que um motorista foi designado para sua corrida.",
    description3:
      "HISTÓRICO DE CORRIDAS: Após o término da corrida, terá um histórico no app, no qual o cliente poderá ver as informações da corrida e também contactar o motorista.",
    description4:
      "CANCELAR CORRIDA: Caso o usuário tenha pedido uma corrida por engano o mesmo poderá cancelar a corrida através do app.",
    description5:
      "O QUE OCORRERÁ SE O MOTORISTA CANCELAR A CORRIDA: O aplicativo irá procurar automaticamente outro motorista para a sua corrida.",
    description6:
      "COMO PAGAR PELA CORRIDA: O usuário poderá pagar diretamente ao motorista pela forma de pagamento que preferir (pix, dinheiro, cartão de débito ou cartão de crédito) também terá uma opção de pagar a corrida pelo próprio aplicativo.",
    description7:
      "AVALIAÇÃO: Após a corrida, o usuário receberá uma notificação para avaliar o motorista e a corrida.",
    description8:
      "ITEM ESQUECIDO: Caso o usuário tenha esquecido algo no carro do motorista, o mesmo poderá entrar em contato com o motorista através do histórico de viagens.",
    description9:
      "O QUE ACONTECE SE EU PERDER MINHA CORRIDA: Será cobrado do usuário uma taxa com um valor fixo.",
    description10:
      "A CORRIDA TERÁ UM RECIBO: Sim, dentro do aplicativo terá a opção de visualizar as últimas corridas realizadas.",
  },

  account: {
    title: "CONTA",
    description1:
      "COMO CRIAR UMA CONTA: Para criar uma conta no aplicativo, basta seguir as instruções de cadastro e fornecer as informações necessárias, como nome, e-mail e número de telefone, etc.",
    description2:
      "ESQUECI A SENHA: No aplicativo terá uma opção de redefinição de senha.",
    description3:
      "COMO ADICIONAR UM MÉTODO DE PAGAMENTO: Ao entrar no aplicativo, o usuário poderá acessar o menu, onde terá um botão que irá direcioná-lo para a área no qual poderá adicionar a forma de pagamento.",
    description4:
      "QUAIS MÉTODOS DE PAGAMENTO ESTÃO DISPONÍVEIS: Será possível realizar o pagamento via PIX, dinheiro, cartão de crédito, débito diretamente com o motorista ou através da plataforma de pagamento do app.",
    description5:
      "O APLICATIVO ARMAZENA MEUS DADOS DE FORMA SEGURA: Sim os dados da forma de pagamento serão criptografados.",
    description6:
      "EXISTE ALGUMA TAXA DE MANUTENÇÃO DO APP PARA O USUÁRIO: Não, a assinatura será apenas para que o usuário possa solicitar um motorista do aplicativo.",
  },

  security: {
    title: "SEGURANÇA",
    description1:
      "O APLICATIVO OFERECE RECURSOS DE SEGURANÇA: São oferecidos acompanhamento em tempo real da rota de viagem, a possibilidade de compartilhar a viagem e os dados do motorista e do carro que ele dirige.",
    description2:
      "O APLICATIVO TERÁ ACESSO À MINHA LOCALIZAÇÃO: Caso o usuário permita que o app use a localização sim, para fins de localizar o cliente e facilitar no momento de inserir o endereço de partida.",
    description3:
      "VERIFICAÇÃO DE MOTORISTA: Todos os motoristas serão verificados ao fazer o cadastro no app para ser um motorista, aumentando o nível de segurança para o usuário do app.",
    description4:
      "ANTES DE ENTRAR NO VEÍCULO VERIFIQUE: A placa do carro, se ela não for compatível com a placa mostrada no aplicativo não entre no veículo.",
  },

  teste: {
    title: "TESTE",
    description1: "TESTE1",
    description2: "TESTE2",
    description3: "TESTE3",
    description4: "TESTE4",
    description5: "TESTE5",
    description6: "TESTE6",
  },
};
