import {
  Box,
  Heading,
  Image,
  Flex,
  Grid,
  useMediaQuery,
  Text,
} from "@chakra-ui/react";
import shield from "../../assets/images/shield.png";
import profits from "../../assets/images/profits.png";
import target from "../../assets/images/target.png";

export const Goals = () => {
  const [lessthan524] = useMediaQuery(["(max-width: 500px)"]);
  const textFontSize = ["0.8rem", "lg", "xl", "2xl", "3xl"];

  return (
    <Flex
      id="metas"
      p={lessthan524 ? "2rem" : "4rem"}
      bg="#000"
      direction={"column"}
      align={"center"}
    >
      <Heading
        color="#FDD835"
        marginBottom={lessthan524 ? "4rem" : "8rem"}
        marginTop={lessthan524 ? "1rem" : "4rem"}
        fontSize={"3rem"}
        fontFamily={"Montserrat"}
        fontWeight={"light"}
        // marginX={lessthan524 ? "7.5%" : "15%"}
      >
        METAS
      </Heading>

      <Flex
        w={"full"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        flexWrap={"wrap"}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          maxWidth={"300px"}
          marginBottom={"6rem"}
        >
          <Box p={"2rem"} borderRadius="50%" backgroundColor="#FDD835">
            <Image
              bottom={"2rem"}
              h={"3rem"}
              w={"3rem"}
              src={target}
              alt="Target"
            />
          </Box>

          <Text
            w={"full"}
            textAlign={"center"}
            paddingTop={"2rem"}
            color={"white"}
            fontWeight={"light"}
            fontSize={lessthan524 ? "1rem" : "1.6rem"}
          >
            Profissionalizar a categoria.
          </Text>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          maxWidth={"300px"}
          marginBottom={"6rem"}
        >
          <Box
            p={"2rem"}
            bottom={"6rem"}
            borderRadius="50%"
            backgroundColor="#FDD835"
          >
            <Image
              bottom={"2rem"}
              h={"3rem"}
              w={"3rem"}
              src={shield}
              alt="Shield"
            />
          </Box>
          <Text
            w={"full"}
            textAlign={"center"}
            paddingTop={"50px"}
            color={"white"}
            fontWeight={"light"}
            fontSize={lessthan524 ? "1rem" : "1.6rem"}
          >
            Conforto e qualidade.
          </Text>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          maxWidth={"300px"}
          marginBottom={"6rem"}
        >
          <Box
            p={"2rem"}
            bottom={"6rem"}
            borderRadius="50%"
            backgroundColor="#FDD835"
          >
            <Image
              bottom={"2rem"}
              h={"3rem"}
              w={"3rem"}
              src={profits}
              alt="Profits"
            />
          </Box>
          <Text
            w={"full"}
            textAlign={"center"}
            paddingTop={"50px"}
            color={"white"}
            fontWeight={"light"}
            fontSize={lessthan524 ? "1rem" : "1.6rem"}
          >
            Crescer no mercado de trabalho.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

